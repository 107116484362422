import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, PlusIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';
import Switch from './switch';

class CreateNewProptree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            listings: [],
            user_enterprise: {},
            enterprises: [],
            forms: [],
            feed: {},
            version: {},
            access: {},
            adbooster_type: { id: 1, name: "Property Booster" },
            dontShowSubmitButton: false,
            preview: null,
            proptree: {},
            page_listings: 1,
            total_listings: 0,
            sale_listings: 0,
            rent_listings: 0,
            loading_listings: false,
            other_forms: [],
            tab: { name: "Agency link", value: 'agency' }
        }
    };

    componentDidMount() { }

    async componentWillReceiveProps(nextProps) {
        try {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            if (!this.state.open && nextProps.open) {
                this.functions.getFeeds();
                this.state.proptree = (nextProps.proptree ? nextProps.proptree : { profileCompany: nextProps.enterprise.companyName, listings: {} });
                this.state.preview = true;
                let market = nextProps.enterprise && nextProps.enterprise.market && nextProps.enterprise.market.value ? nextProps.enterprise.market.value : "ae";
                let currency = nextProps.enterprise && nextProps.enterprise.baseCurrency && nextProps.enterprise.baseCurrency.value ? nextProps.enterprise.baseCurrency.value.toUpperCase() : "AED";
                await this.promisedSetState({
                    base_language: this.renders.localize(market, "base_language", currency),
                    base_currency: this.renders.localize(market, "base_currency", currency),
                    base_size_unit: this.renders.localize(market, "base_size_unit", currency),
                    base_dial_code: this.renders.localize(market, "base_dial_code", currency),
                });
                if (this.state.proptree.id && !this.state.proptree.externalAccess) {
                    this.state.proptree.externalAccess = { id: 2, name: 'Enabled', value: 'enabled' };
                }
                await this.promisedSetState({
                    proptree: this.state.proptree
                });
                if (this.state.proptree.id) {
                    this.functions.getListings();
                }
                console.log("this.state.proptree.feed", this.state.proptree.feed);
                if (this.state.proptree.feed) {
                    this.functions.listExternalProptrees();
                }
            }
            await this.promisedSetState({
                proptree: this.state.proptree,
                open: nextProps.open,
                user_enterprise: nextProps.enterprise,
                dontShowSubmitButton: nextProps.dontShowSubmitButton,
                preview: this.state.preview
            });
        } catch (error) {
            console.log(error);
        }
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });
        },
        getFeeds: async () => {
            try {
                await this.promisedSetState({ loading_feeds: true });
                let response = await this.calls.getFeeds();
                this.setState({
                    feeds: response.data,
                    total: response.meta ? response.meta.total : 0,
                    loading_feeds: false
                })
            } catch (error) {
                this.setState({
                    loading: false,
                    loading_feeds: false
                })
            }
        },
        createProptree: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let proptree = JSON.parse(JSON.stringify(this.state.proptree));
                await this.calls.createProptree(proptree);
                await this.promisedSetState({ loading: false });
                if (this.props.onCreate) {
                    this.props.onCreate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        updateProptree: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let proptree = JSON.parse(JSON.stringify(this.state.proptree));
                await this.calls.updateProptree(proptree);
                await this.promisedSetState({ loading: false });
                if (this.props.onUpdate) {
                    this.props.onUpdate();
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        uploadImage: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_image: true
                }, () => {
                    this.calls.uploadImage(formData).then((response) => {
                        this.state.proptree.profileImage = response.data;
                        this.setState({
                            proptree: this.state.proptree,
                            loading_image: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_image: false
                        })
                    });
                });
            }
        },
        getListings: async () => {
            try {
                await this.promisedSetState({ loading_listings: true });
                let response = await this.calls.getListings();
                await this.promisedSetState({
                    listings: response.data,
                    total_listings: response.meta[(this.state.proptree.propertiesType ? this.state.proptree.propertiesType.value : "both")] ? response.meta[(this.state.proptree.propertiesType ? this.state.proptree.propertiesType.value : "both")] : response.meta.total,
                    sale_listings: response.meta.sale ? response.meta.sale : 0,
                    rent_listings: response.meta.rent ? response.meta.rent : 0,
                    loading_listings: false
                });
            } catch (error) {
                this.setState({
                    error_listings: true,
                    message_listings: error.body ? error.body.message : "Something went wrong",
                    loading_listings: false
                })
            }
        },
        listExternalProptrees: async () => {
            try {
                await this.promisedSetState({ loading_forms: true });
                let response = await this.calls.listExternalProptrees();
                console.log("response", response);
                this.setState({
                    other_forms: response.data,
                    loading_forms: false
                });
            } catch (error) {
                console.log("error", error);
                this.setState({
                    loading_forms: false
                })
            }
        }
    };

    renders = {
        valid: () => {
            let valid_answers = [];
            let mandatory = ["profileImage", "profileFirstName", "profileCompany", "whatsAppNumber", "whatsAppCountryCode", "propertiesType", "property"];
            if (!(this.state.proptree.externalAccess && this.state.proptree.externalAccess.value == 'enabled')) {
                mandatory = mandatory.filter((item) => { return item !== 'whatsAppNumber' && item !== 'whatsAppCountryCode' })
            }
            mandatory.map((item) => {
                if (item == "whatsAppCountryCode") {
                    if (this.state.proptree.whatsAppCountryCode && this.state.proptree.whatsAppCountryCode.value) {
                        valid_answers.push(item);
                    }
                } else if (item == "propertiesType") {
                    if (this.state.proptree.propertiesType && this.state.proptree.propertiesType.value) {
                        valid_answers.push(item);
                    }
                } else if (item == "property") {
                    if (this.state.proptree.property && this.state.proptree.property.value) {
                        valid_answers.push(item);
                    }
                } else if (typeof this.state.proptree[item] == "string" && this.state.proptree[item] !== "") {
                    valid_answers.push(item);
                }
            });
            if (valid_answers.length == mandatory.length) {
                if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                    return this.state.proptree.listings && Object.keys(this.state.proptree.listings).length > 0
                } else {
                    return valid_answers.length == mandatory.length;
                }
            }
        },
        previewUrl: () => {
            let string = "https://app.adbooster.ai/123/proptree?preview=true";
            if (window.location.hostname === 'localhost') {
                string = "http://localhost:5001/123/proptree?preview=true";
            }
            if (this.state.user_enterprise && this.state.user_enterprise.id) {
                string += "&enterprise=" + this.state.user_enterprise.id;
            }
            if (this.state.proptree.id) {
                string += "&proptreeId=" + this.state.proptree.id;
            }
            if (this.state.proptree.alias) {
                string += "&alias=" + this.state.proptree.alias;
            }
            if (this.state.proptree.profileFirstName) {
                string += "&profileFirstName=" + this.state.proptree.profileFirstName;
            }
            if (this.state.proptree.profileImage) {
                string += "&profileImage=" + this.state.proptree.profileImage;
            }
            if (this.state.proptree.profileCompany) {
                string += "&profileCompany=" + this.state.proptree.profileCompany.trim();
            }
            if ((!this.state.proptree.mainFeed) || (this.state.proptree.mainFeed && this.state.proptree.mainFeed.value == 'enabled')) {
                string += "&mainFeed=true";
            }
            if (this.state.proptree.propertiesType && this.state.proptree.propertiesType.value) {
                string += "&propertiesType=" + this.state.proptree.propertiesType.value;
            }
            if (this.state.proptree.property && this.state.proptree.property.value) {
                string += "&property=" + this.state.proptree.property.value;
            }
            if (this.state.proptree.agencyDefault && ((this.state.proptree.agency && this.state.proptree.agency.showAgentFeed && this.state.proptree.agency.showAgentFeed.value == 'enabled') || !(this.state.proptree.agency && this.state.proptree.agency.showAgentFeed))) {
                string += "&showAgentFeed=true";
            }
            if (this.state.proptree.agency && this.state.proptree.agency.showFeedBanner && this.state.proptree.agency.showFeedBanner.value == 'enabled' && this.state.proptree.agencyDefault) {
                string += "&showFeedBanner=true";
            }
            if (this.state.proptree.agency && this.state.proptree.agency.showFeedBanner && this.state.proptree.agency.showFeedBanner.value == 'enabled' && this.state.proptree.agency.externalFeed) {
                string += "&secondaryFeed=" + this.state.proptree.agency.externalFeed.id;
            }
            if (this.state.proptree.agency && this.state.proptree.agency.showAgencyBanner && this.state.proptree.agency.showAgencyBanner.value == 'enabled') {
                string += "&showAgencyBanner=true";
            }
            if (this.state.proptree.agencyDefault) {
                string += "&agencyDefault=true";
            }
            if (this.state.proptree.propertiesType && this.state.proptree.propertiesType.value && this.state.proptree.property && this.state.proptree.property.value) {
                if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                    if (this.state.proptree.listings && Object.keys(this.state.proptree.listings).length > 0) {
                        string += "&showListings=true";
                        string += "&listings=" + (this.state.proptree.listings ? Object.keys(this.state.proptree.listings) : "");
                    }
                } else {
                    string += "&showListings=true";
                }
            }
            if (this.state.proptree.showButtons) {
                string += "&showButtons=true";
                if (Array.isArray(this.state.proptree.buttons) && this.state.proptree.buttons.length > 0) {
                    this.state.proptree.buttons.map((button, index) => {
                        string += "&buttons[" + index + "][text]=" + button.text + "&buttons[" + index + "][url]=" + button.url;
                    });
                }
            }
            return string;
        },
        localize: (market, type, currency) => {

            //AVAILABLE MARKETS
            //TODO: add new markets here
            //TODO: some translations are done directly in form front-end, be sure to add support for new platform markets and langs also in front-end

            //console.log(market + " " + type + " " + currency);

            // Default values
            let currency_before = true;
            let base_language = "en";
            let base_currency = currency || "AED";
            let base_size_unit = "sqft";
            let base_dial_code = "971";

            // Market-specific overrides
            if (market === "ae" || market === "uae") {
                currency_before = true;
                base_language = "en";
                base_currency = currency || "AED";
                base_size_unit = "sqft";
                base_dial_code = "971";
            } else if (market === "se") {
                currency_before = false;
                base_language = "sv";
                base_currency = currency || "SEK";
                base_size_unit = "sqm";
                base_dial_code = "46";
            } else if (market === "gb") {
                currency_before = true;
                base_language = "en";
                base_currency = currency || "GBP";
                base_size_unit = "sqft";
                base_dial_code = "44";
            }

            switch (type) {
                case "base_language":
                    return base_language;
                case "base_currency":
                    return base_currency.toUpperCase();
                case "base_size_unit":
                    return base_size_unit;
                case "base_dial_code":
                    return base_dial_code;
            }
        },
        fromAdmin: () => {
            return this.state.user && this.state.user.from_admin;
        },
        validAlias: (string) => {
            let valid = false;
            let length = 30; //only agency and affiliate can have max 30 chars, agents max 20 chars
            try {
                if ((/^[a-zA-Z0-9_-]+$/i).test(string)) {
                    valid = true;
                }
                if (string.length > length) {
                    valid = false;
                }
            } catch (error) { }
            return valid;
        },
    };

    calls = {
        getFeeds: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed-light?type=proptree";
            return apiRegister.call(options, url);
        },
        updateProptree: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/proptree/updateProptree/" + this.state.proptree.id;
            return apiRegister.call(options, url);
        },
        createProptree: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/createProptree";
            return apiRegister.call(options, url);
        },
        uploadImage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/image?proptree=true&enterprise=" + (this.state.user_enterprise && this.state.user_enterprise.id ? this.state.user_enterprise.id : "") + (this.state.proptree.shortId ? "&shortId=" + this.state.proptree.shortId : "");
            return apiRegister.call(options, url);
        },
        getListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/listings/getListingsShortInfo?page=" + this.state.page_listings + "&limit=100000&orderBy=ascending&sortBy=reference&type=" + (this.state.proptree.propertiesType ? this.state.proptree.propertiesType.value : "both") + "&status=ready" + (this.state.proptree.property && this.state.proptree.property.value ? ("&property=" + this.state.proptree.property.value) : "");
            return apiRegister.call(options, url);
        },
        listExternalProptrees: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/proptree/listExternalProptrees" + (this.state.proptree && this.state.proptree.feed ? ("?currency=" + this.state.proptree.feed.currency.value + "&language=" + this.state.proptree.feed.language.value) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-7xl rounded-lg overflow-hidden">
                                    <div className="h-full flex flex-row bg-white shadow-xl">

                                        <div className="md:flex hidden flex-1 flex-col">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">Preview</Dialog.Title>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center mt-12 px-4 sm:px-6">
                                                <div className="bg-gray-100 relative border overflow-hidden" style={{ borderRadius: '30px', width: "350px", height: "600px" }}>
                                                    {
                                                        this.state.preview &&
                                                        <iframe
                                                            id="proptree_preview"
                                                            src={this.renders.previewUrl()}
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                border: "none",
                                                                //objectFit: "contain", /* creates issue with safari */
                                                            }}
                                                            frameBorder="0"
                                                            //scrolling="no"
                                                            allowFullScreen
                                                            aria-hidden="false"
                                                            tabIndex="0"
                                                        ></iframe>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="max-w-4xl w-full h-full flex flex-col border-l">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.proptree.id ? this.state.proptree.agencyDefault ? "Update Agency Proptree" : "Update Affiliate Proptree" : this.state.proptree.agencyDefault ? "New Agency Proptree" : "New Affiliate Proptree"}</Dialog.Title>
                                                    <div className="ml-3 h-10 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 h-0 overflow-y-auto">
                                                <div className="flex flex-row">
                                                    <div className="flex flex-1 flex-col pt-6">
                                                        <div className="px-4 sm:grid-cols-12 grid-cols-4 grid gap-5 sm:px-6">
                                                            <div className="col-span-4 flex">
                                                                <div className="mt-1 flex items-center">
                                                                    {
                                                                        !this.state.proptree.profileImage &&
                                                                        <span className="inline-block h-20 w-20 rounded-full relative overflow-hidden bg-gray-100">
                                                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                            </svg>
                                                                            {
                                                                                this.state.loading_image &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </span>
                                                                    }
                                                                    {
                                                                        this.state.proptree.profileImage &&
                                                                        <div className="rounded-full relative mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center">
                                                                            <div>
                                                                                <img
                                                                                    className="h-20 w-20 object-cover"
                                                                                    src={this.state.proptree.profileImage}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            {
                                                                                this.state.loading_image &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    <div className="ml-4 flex">
                                                                        <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                                            <label
                                                                                htmlFor="user-photo"
                                                                                className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                                                                            >
                                                                                {
                                                                                    this.state.proptree.profileImage &&
                                                                                    <span>Change image</span>
                                                                                }
                                                                                {
                                                                                    !this.state.proptree.profileImage &&
                                                                                    <span>Upload image</span>
                                                                                }

                                                                            </label>
                                                                            {
                                                                                <input
                                                                                    ref="uploadImageFile"
                                                                                    onClick={() => this.refs.uploadImageFile.value = null}
                                                                                    onChange={(e) => this.functions.uploadImage(e)}
                                                                                    type="file"
                                                                                    accept={"image/png,image/jpg,image/jpeg"}
                                                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-8 divide-y divide-gray-200">
                                                                <div className=" sm:pb-5 flex">
                                                                    <div className="flex flex-1">
                                                                        <div className="w-full">
                                                                            <label htmlFor="proptree-shortname" className="block text-sm font-medium text-gray-900">
                                                                                Custom shortname (optional)
                                                                            </label>
                                                                            <div className="mt-1 flex">
                                                                                <div className="bg-gray-50 border text-sm border-r-none rounded-l-md shadow-sm items-center inline-flex h-10 px-4">
                                                                                    https://proptr.ee/
                                                                                </div>
                                                                                <input
                                                                                    onChange={(event) => {
                                                                                        this.state.proptree.alias = event.target.value;
                                                                                        this.setState({
                                                                                            proptree: this.state.proptree
                                                                                        })
                                                                                    }}
                                                                                    value={this.state.proptree.alias ? this.state.proptree.alias : ""}
                                                                                    type="text"
                                                                                    name="proptree-shortname"
                                                                                    id="proptree-shortname"
                                                                                    className={(this.state.alias !== "" && !this.renders.validAlias(this.state.proptree.alias) ? "border-red-500 text-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + "appearance-none block rounded-l-none w-full px-3 sm:py-2 py-1 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none text-sm"}
                                                                                    //className={"appearance-none block rounded-l-none w-full px-3 sm:py-2 py-1 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none text-sm"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 mt-4 grid-cols-6 grid gap-5 sm:px-6">
                                                            <div className="sm:col-span-3 col-span-6 divide-y divide-gray-200">
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="proptree-name" className="block text-sm font-medium text-gray-900">
                                                                            Tagline
                                                                        </label>
                                                                        <div className="mt-1 flex">
                                                                            <input
                                                                                onChange={(event) => {
                                                                                    this.state.proptree.profileFirstName = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }}
                                                                                value={this.state.proptree.profileFirstName ? this.state.proptree.profileFirstName : ""}
                                                                                type="text"
                                                                                name="proptree-name"
                                                                                id="proptree-name"
                                                                                className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="sm:col-span-3 mb-4 flex col-span-6">
                                                                <div className="flex flex-1">
                                                                    <div className="w-full">
                                                                        <div>
                                                                            <label htmlFor="proptree-company" className="block text-sm font-medium text-gray-900">
                                                                                Company
                                                                            </label>
                                                                            <div className="mt-1 flex">
                                                                                <input
                                                                                    onChange={(event) => {
                                                                                        this.state.proptree.profileCompany = event.target.value;
                                                                                        this.setState({
                                                                                            proptree: this.state.proptree
                                                                                        })
                                                                                    }}
                                                                                    value={this.state.proptree.profileCompany ? this.state.proptree.profileCompany : ""}
                                                                                    disabled={true}
                                                                                    type="text"
                                                                                    name="proptree-company"
                                                                                    id="proptree-company"
                                                                                    className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" + (true ? " bg-gray-100 cursor-not-allowed" : "")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.renders.fromAdmin() &&
                                                                    <div className="ml-3">
                                                                        <div className="">
                                                                            <div>
                                                                                <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                                    Agency proptree
                                                                                </label>
                                                                                <div style={{ marginTop: "10px" }} className="">
                                                                                    <Switch
                                                                                        value={this.state.proptree.agencyDefault}
                                                                                        disabled={this.state.proptree.id}
                                                                                        onSwitch={async () => {
                                                                                            if (!this.state.proptree.id) {
                                                                                                this.state.proptree.listings = {};
                                                                                                this.state.proptree.agencyDefault = !this.state.proptree.agencyDefault;
                                                                                                await this.promisedSetState({
                                                                                                    proptree: this.state.proptree
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="col-span-6 relative grid-cols-12 grid gap-5 -mt-5 mb-5">
                                                                <div className="sm:col-span-3 col-span-12">
                                                                    <label htmlFor="proptree-banner" className="block text-sm font-medium text-gray-900">
                                                                        External access
                                                                    </label>
                                                                    <div className="mt-1 relative">
                                                                        <SingleSelection
                                                                            select={async (option) => {
                                                                                this.state.proptree.externalAccess = option;
                                                                                await this.promisedSetState({
                                                                                    proptree: this.state.proptree
                                                                                });
                                                                            }}
                                                                            disabled={this.state.proptree.id}
                                                                            selected={this.state.proptree.externalAccess ? this.state.proptree.externalAccess : (this.state.proptree.id ? { id: 2, name: 'Enabled', value: 'enabled' } : { id: 1, name: 'Disabled', value: 'disabled' })}
                                                                            options={[
                                                                                { id: 1, name: 'Disabled', value: 'disabled' },
                                                                                { id: 2, name: 'Enabled', value: 'enabled' }
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="sm:col-span-3 col-span-12 relative">
                                                                    {
                                                                        //!this.state.proptree.id &&
                                                                        !(this.state.proptree.externalAccess && this.state.proptree.externalAccess.value == "enabled") &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <label htmlFor="proptree-dial-code" className="block text-sm font-medium text-gray-900">
                                                                        Country code
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <SingleSelection
                                                                            select={async (option) => {
                                                                                this.state.proptree.whatsAppCountryCode = option;
                                                                                await this.promisedSetState({
                                                                                    proptree: this.state.proptree
                                                                                });
                                                                            }}
                                                                            disabled={this.state.proptree.id}
                                                                            selected={this.state.proptree.whatsAppCountryCode ? this.state.proptree.whatsAppCountryCode : { id: 0, name: 'Click to Select' }}
                                                                            options={[
                                                                                { id: 9, name: 'Denmark (+45)', value: "45" },
                                                                                { id: 7, name: 'France (+33)', value: "33" },
                                                                                { id: 4, name: 'Germany (+49)', value: "49" },
                                                                                { id: 5, name: 'Ireland (+353)', value: "353" },
                                                                                { id: 6, name: 'Norway (+47)', value: "47" },
                                                                                { id: 3, name: 'Sweden (+46)', value: "46" },
                                                                                { id: 8, name: 'Switzerland (+41)', value: "41" },
                                                                                { id: 2, name: 'UK (+44)', value: "44" },
                                                                                { id: 10, name: 'Australia (+61)', value: "61" },
                                                                                { id: 11, name: 'Canada/US (+1)', value: "1" },
                                                                                { id: 12, name: 'India (+91)', value: "91" },
                                                                                { id: 13, name: 'Israel (+972)', value: "972" },
                                                                                { id: 14, name: 'New Zealand (+64)', value: "64" },
                                                                                { id: 15, name: 'Qatar (+974)', value: "974" },
                                                                                { id: 16, name: 'Saudi Arabia (+966)', value: "966" },
                                                                                { id: 17, name: 'South Africa (+27)', value: "27" },
                                                                                { id: 1, name: 'UAE (+971)', value: "971" },
                                                                                { id: 19, name: 'Spain (+34)', value: "34" },
                                                                                { id: 20, name: 'Portugal (+351)', value: "351" },
                                                                                { id: 21, name: 'Netherlands (+31)', value: "31" },
                                                                                { id: 22, name: 'Italy (+39)', value: "39" },
                                                                                { id: 23, name: 'Afghanistan (+93)', value: "93" },
                                                                                { id: 24, name: 'Albania (+355)', value: "355" },
                                                                                { id: 25, name: 'Algeria (+213)', value: "213" },
                                                                                { id: 26, name: 'American Samoa (+1684)', value: "1684" },
                                                                                { id: 27, name: 'Andorra (+376)', value: "376" },
                                                                                { id: 28, name: 'Angola (+244)', value: "244" },
                                                                                { id: 29, name: 'Antigua & Barbuda (+1268)', value: "1268" },
                                                                                { id: 30, name: 'Argentina (+54)', value: "54" },
                                                                                { id: 31, name: 'Armenia (+374)', value: "374" },
                                                                                { id: 32, name: 'Austria (+43)', value: "43" },
                                                                                { id: 33, name: 'Azerbaijan (+994)', value: "994" },
                                                                                { id: 34, name: 'Bahamas (+1242)', value: "1242" },
                                                                                { id: 35, name: 'Bahrain (+973)', value: "973" },
                                                                                { id: 36, name: 'Bangladesh (+880)', value: "880" },
                                                                                { id: 37, name: 'Barbados (+1246)', value: "1246" },
                                                                                { id: 38, name: 'Belarus (+375)', value: "375" },
                                                                                { id: 39, name: 'Belgium (+32)', value: "32" },
                                                                                { id: 40, name: 'Belize (+501)', value: "501" },
                                                                                { id: 41, name: 'Benin (+229)', value: "229" },
                                                                                { id: 42, name: 'Bhutan (+975)', value: "975" },
                                                                                { id: 43, name: 'Bolivia (+591)', value: "591" },
                                                                                { id: 44, name: 'Bosnia & Herzegovina (+387)', value: "387" },
                                                                                { id: 45, name: 'Botswana (+267)', value: "267" },
                                                                                { id: 46, name: 'Brazil (+55)', value: "55" },
                                                                                { id: 47, name: 'Brunei (+673)', value: "673" },
                                                                                { id: 48, name: 'Bulgaria (+359)', value: "359" },
                                                                                { id: 49, name: 'Burkina Faso (+226)', value: "226" },
                                                                                { id: 50, name: 'Burundi (+257)', value: "257" },
                                                                                { id: 51, name: 'Cabo Verde (+238)', value: "238" },
                                                                                { id: 52, name: 'Cambodia (+855)', value: "855" },
                                                                                { id: 53, name: 'Cameroon (+237)', value: "237" },
                                                                                { id: 54, name: 'Central African Republic (+236)', value: "236" },
                                                                                { id: 55, name: 'Chad (+235)', value: "235" },
                                                                                { id: 56, name: 'Chile (+56)', value: "56" },
                                                                                { id: 57, name: 'China (+86)', value: "86" },
                                                                                { id: 58, name: 'Colombia (+57)', value: "57" },
                                                                                { id: 59, name: 'Comoros (+269)', value: "269" },
                                                                                { id: 60, name: 'Congo (+242)', value: "242" },
                                                                                { id: 61, name: 'Costa Rica (+506)', value: "506" },
                                                                                { id: 62, name: 'Croatia (+385)', value: "385" },
                                                                                { id: 63, name: 'Cuba (+53)', value: "53" },
                                                                                { id: 64, name: 'Cyprus (+357)', value: "357" },
                                                                                { id: 65, name: 'Czech Republic (+420)', value: "420" },
                                                                                { id: 66, name: 'Djibouti (+253)', value: "253" },
                                                                                { id: 67, name: 'Dominica (+1767)', value: "1767" },
                                                                                { id: 68, name: 'Dominican Republic (+1809)', value: "1809" },
                                                                                { id: 69, name: 'Ecuador (+593)', value: "593" },
                                                                                { id: 70, name: 'Egypt (+20)', value: "20" },
                                                                                { id: 71, name: 'El Salvador (+503)', value: "503" },
                                                                                { id: 72, name: 'Equatorial Guinea (+240)', value: "240" },
                                                                                { id: 73, name: 'Eritrea (+291)', value: "291" },
                                                                                { id: 74, name: 'Estonia (+372)', value: "372" },
                                                                                { id: 75, name: 'Eswatini (+268)', value: "268" },
                                                                                { id: 76, name: 'Ethiopia (+251)', value: "251" },
                                                                                { id: 77, name: 'Fiji (+679)', value: "679" },
                                                                                { id: 78, name: 'Finland (+358)', value: "358" },
                                                                                { id: 79, name: 'Gabon (+241)', value: "241" },
                                                                                { id: 80, name: 'Gambia (+220)', value: "220" },
                                                                                { id: 81, name: 'Georgia (+995)', value: "995" },
                                                                                { id: 82, name: 'Ghana (+233)', value: "233" },
                                                                                { id: 83, name: 'Greece (+30)', value: "30" },
                                                                                { id: 84, name: 'Grenada (+1473)', value: "1473" },
                                                                                { id: 85, name: 'Guatemala (+502)', value: "502" },
                                                                                { id: 86, name: 'Guinea (+224)', value: "224" },
                                                                                { id: 87, name: 'Guinea-Bissau (+245)', value: "245" },
                                                                                { id: 88, name: 'Guyana (+592)', value: "592" },
                                                                                { id: 89, name: 'Haiti (+509)', value: "509" },
                                                                                { id: 90, name: 'Honduras (+504)', value: "504" },
                                                                                { id: 195, name: 'Hong Kong (+852)', value: "852" },
                                                                                { id: 91, name: 'Hungary (+36)', value: "36" },
                                                                                { id: 92, name: 'Iceland (+354)', value: "354" },
                                                                                { id: 93, name: 'Indonesia (+62)', value: "62" },
                                                                                { id: 94, name: 'Iran (+98)', value: "98" },
                                                                                { id: 95, name: 'Iraq (+964)', value: "964" },
                                                                                { id: 98, name: 'Jamaica (+1876)', value: "1876" },
                                                                                { id: 99, name: 'Japan (+81)', value: "81" },
                                                                                { id: 100, name: 'Jordan (+962)', value: "962" },
                                                                                { id: 101, name: 'Kazakhstan (+7)', value: "7" },
                                                                                { id: 102, name: 'Kenya (+254)', value: "254" },
                                                                                { id: 103, name: 'Kiribati (+686)', value: "686" },
                                                                                //{ id: 104, name: 'South Korea (+82)', value: "82" },
                                                                                { id: 105, name: 'Kuwait (+965)', value: "965" },
                                                                                { id: 106, name: 'Kyrgyzstan (+996)', value: "996" },
                                                                                { id: 107, name: 'Laos (+856)', value: "856" },
                                                                                { id: 108, name: 'Latvia (+371)', value: "371" },
                                                                                { id: 109, name: 'Lebanon (+961)', value: "961" },
                                                                                { id: 110, name: 'Lesotho (+266)', value: "266" },
                                                                                { id: 111, name: 'Liberia (+231)', value: "231" },
                                                                                { id: 112, name: 'Libya (+218)', value: "218" },
                                                                                { id: 113, name: 'Liechtenstein (+423)', value: "423" },
                                                                                { id: 114, name: 'Lithuania (+370)', value: "370" },
                                                                                { id: 115, name: 'Luxembourg (+352)', value: "352" },
                                                                                { id: 116, name: 'Madagascar (+261)', value: "261" },
                                                                                { id: 117, name: 'Malawi (+265)', value: "265" },
                                                                                { id: 118, name: 'Malaysia (+60)', value: "60" },
                                                                                { id: 119, name: 'Maldives (+960)', value: "960" },
                                                                                { id: 120, name: 'Mali (+223)', value: "223" },
                                                                                { id: 121, name: 'Malta (+356)', value: "356" },
                                                                                { id: 122, name: 'Marshall Islands (+692)', value: "692" },
                                                                                { id: 123, name: 'Mauritania (+222)', value: "222" },
                                                                                { id: 124, name: 'Mauritius (+230)', value: "230" },
                                                                                { id: 125, name: 'Mexico (+52)', value: "52" },
                                                                                { id: 126, name: 'Micronesia (+691)', value: "691" },
                                                                                { id: 127, name: 'Moldova (+373)', value: "373" },
                                                                                { id: 128, name: 'Monaco (+377)', value: "377" },
                                                                                { id: 129, name: 'Mongolia (+976)', value: "976" },
                                                                                { id: 130, name: 'Montenegro (+382)', value: "382" },
                                                                                { id: 131, name: 'Morocco (+212)', value: "212" },
                                                                                { id: 132, name: 'Mozambique (+258)', value: "258" },
                                                                                { id: 133, name: 'Myanmar (+95)', value: "95" },
                                                                                { id: 134, name: 'Namibia (+264)', value: "264" },
                                                                                { id: 135, name: 'Nauru (+674)', value: "674" },
                                                                                { id: 136, name: 'Nepal (+977)', value: "977" },
                                                                                { id: 137, name: 'Nicaragua (+505)', value: "505" },
                                                                                { id: 138, name: 'Niger (+227)', value: "227" },
                                                                                { id: 139, name: 'Nigeria (+234)', value: "234" },
                                                                                { id: 140, name: 'North Macedonia (+389)', value: "389" },
                                                                                { id: 142, name: 'Oman (+968)', value: "968" },
                                                                                { id: 143, name: 'Pakistan (+92)', value: "92" },
                                                                                { id: 144, name: 'Palau (+680)', value: "680" },
                                                                                { id: 145, name: 'Panama (+507)', value: "507" },
                                                                                { id: 146, name: 'Papua New Guinea (+675)', value: "675" },
                                                                                { id: 147, name: 'Paraguay (+595)', value: "595" },
                                                                                { id: 148, name: 'Peru (+51)', value: "51" },
                                                                                { id: 149, name: 'Philippines (+63)', value: "63" },
                                                                                { id: 150, name: 'Poland (+48)', value: "48" },
                                                                                { id: 151, name: 'Romania (+40)', value: "40" },
                                                                                { id: 152, name: 'Russia (+7)', value: "7" },
                                                                                { id: 153, name: 'Rwanda (+250)', value: "250" },
                                                                                { id: 154, name: 'Saint Kitts & Nevis (+1869)', value: "1869" },
                                                                                { id: 155, name: 'Saint Lucia (+1758)', value: "1758" },
                                                                                { id: 156, name: 'Saint Vincent & Grenadines (+1784)', value: "1784" },
                                                                                { id: 157, name: 'Samoa (+685)', value: "685" },
                                                                                { id: 158, name: 'San Marino (+378)', value: "378" },
                                                                                { id: 159, name: 'Sao Tome & Principe (+239)', value: "239" },
                                                                                { id: 160, name: 'Senegal (+221)', value: "221" },
                                                                                { id: 161, name: 'Serbia (+381)', value: "381" },
                                                                                { id: 162, name: 'Seychelles (+248)', value: "248" },
                                                                                { id: 163, name: 'Sierra Leone (+232)', value: "232" },
                                                                                { id: 164, name: 'Singapore (+65)', value: "65" },
                                                                                { id: 165, name: 'Slovakia (+421)', value: "421" },
                                                                                { id: 166, name: 'Slovenia (+386)', value: "386" },
                                                                                { id: 167, name: 'Solomon Islands (+677)', value: "677" },
                                                                                { id: 168, name: 'South Korea (+82)', value: "82" },
                                                                                { id: 169, name: 'South Sudan (+211)', value: "211" },
                                                                                { id: 170, name: 'Sri Lanka (+94)', value: "94" },
                                                                                { id: 171, name: 'Sudan (+249)', value: "249" },
                                                                                { id: 173, name: 'Syria (+963)', value: "963" },
                                                                                { id: 174, name: 'Taiwan (+886)', value: "886" },
                                                                                { id: 175, name: 'Tajikistan (+992)', value: "992" },
                                                                                { id: 176, name: 'Tanzania (+255)', value: "255" },
                                                                                { id: 177, name: 'Thailand (+66)', value: "66" },
                                                                                { id: 178, name: 'Togo (+228)', value: "228" },
                                                                                { id: 179, name: 'Trinidad & Tobago (+1868)', value: "1868" },
                                                                                { id: 180, name: 'Tunisia (+216)', value: "216" },
                                                                                { id: 181, name: 'Turkey (+90)', value: "90" },
                                                                                { id: 182, name: 'Turkmenistan (+993)', value: "993" },
                                                                                { id: 183, name: 'Tuvalu (+688)', value: "688" },
                                                                                { id: 184, name: 'Uganda (+256)', value: "256" },
                                                                                { id: 185, name: 'Ukraine (+380)', value: "380" },
                                                                                { id: 186, name: 'Uruguay (+598)', value: "598" },
                                                                                { id: 187, name: 'Uzbekistan (+998)', value: "998" },
                                                                                { id: 188, name: 'Vanuatu (+678)', value: "678" },
                                                                                { id: 189, name: 'Vatican City (+379)', value: "379" },
                                                                                { id: 190, name: 'Venezuela (+58)', value: "58" },
                                                                                { id: 191, name: 'Vietnam (+84)', value: "84" },
                                                                                { id: 192, name: 'Yemen (+967)', value: "967" },
                                                                                { id: 193, name: 'Zambia (+260)', value: "260" },
                                                                                { id: 194, name: 'Zimbabwe (+263)', value: "263" }
                                                                            ].sort((a, b) => {
                                                                                if (a.name === 'None') return -1;
                                                                                if (b.name === 'None') return 1;
                                                                                if (a.value === this.state.base_dial_code) return -1;
                                                                                if (b.value === this.state.base_dial_code) return 1;
                                                                                return a.name.localeCompare(b.name);
                                                                            })}
                                                                            name="proptree-dial-code"
                                                                            id="proptree-dial-code"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="sm:col-span-6 col-span-12 relative">
                                                                    {
                                                                        //!this.state.proptree.id &&
                                                                        !(this.state.proptree.externalAccess && this.state.proptree.externalAccess.value == "enabled") &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <label htmlFor="proptree-whatsapp" className="block text-sm font-medium text-gray-900">
                                                                        WhatsApp (used for login)
                                                                    </label>
                                                                    <div className="mt-1 flex">
                                                                        <input
                                                                            onChange={(event) => {
                                                                                if (!this.state.proptree.id) {
                                                                                    this.state.proptree.whatsAppNumber = event.target.value;
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    })
                                                                                }
                                                                            }}
                                                                            readOnly={this.state.proptree.id}
                                                                            disabled={this.state.proptree.id}
                                                                            value={this.state.proptree.whatsAppNumber ? this.state.proptree.whatsAppNumber : ""}
                                                                            type="text"
                                                                            name="proptree-whatsapp"
                                                                            id="proptree-whatsapp"
                                                                            className={(this.state.proptree.id ? "bg-gray-100" : "") + " appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.proptree.agencyDefault &&
                                                            <nav className="px-4 pb-4 flex sm:px-6 pt-2" aria-label="Tabs">
                                                                {
                                                                    [
                                                                        { name: "Agency link", value: 'agency' },
                                                                        { name: "Agent links", value: 'agents' }
                                                                    ].filter((item) => {
                                                                        if (this.state.proptree.agencyDefault) {
                                                                            return true;
                                                                        } else {
                                                                            return item.value == 'agency';
                                                                        }
                                                                    }).map((tab) => (
                                                                        <div
                                                                            key={tab.name}
                                                                            className={classNames(
                                                                                this.state.tab.value == tab.value ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                                                                                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer mr-4 whitespace-no-wrap'
                                                                            )}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    tab: tab
                                                                                })
                                                                            }}
                                                                            aria-current={tab.current ? 'page' : undefined}
                                                                        >
                                                                            {tab.name}
                                                                        </div>
                                                                    ))}
                                                            </nav>
                                                        }
                                                        {
                                                            this.state.tab.value == 'agency' &&
                                                            <div className="px-4 pb-5 grid-cols-12 grid gap-5 sm:px-6">
                                                                {
                                                                    <div className="col-span-12 sm:col-span-3">
                                                                        <div className="w-full mb-5 border-b">
                                                                            <div className="text-sm font-bold pb-1 text-gray-500">Form</div>
                                                                        </div>
                                                                        <div className="w-full">
                                                                            {
                                                                                false &&
                                                                                <label htmlFor="proptree-banner" className="block text-sm font-medium text-gray-900">
                                                                                    Form
                                                                                </label>
                                                                            }
                                                                            <div className="relative">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.feed = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                    }}
                                                                                    disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.feed ? this.state.proptree.feed : { id: 0, name: 'Click to Select' }}
                                                                                    options={this.state.feeds}
                                                                                    name="proptree-feed"
                                                                                    id="proptree-feed"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.proptree.agencyDefault &&
                                                                    <div className="col-span-12 sm:col-span-3">
                                                                        <div className="w-full mb-5 border-b">
                                                                            <div className="text-sm font-bold pb-1 text-gray-500">Agency banner</div>
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <div className="relative">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        if (!this.state.proptree.agency) {
                                                                                            this.state.proptree.agency = {};
                                                                                        }
                                                                                        this.state.proptree.agency.showAgencyBanner = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree,
                                                                                            select_all: false
                                                                                        });
                                                                                    }}
                                                                                    selected={this.state.proptree.agency && this.state.proptree.agency.showAgencyBanner ? this.state.proptree.agency.showAgencyBanner : { id: 2, name: 'Enabled', value: 'enabled' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Disabled', value: 'disabled' },
                                                                                        { id: 2, name: 'Enabled', value: 'enabled' }
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.other_forms && this.state.other_forms.length > 0 && this.state.proptree.agencyDefault &&
                                                                    <div className={(this.state.proptree.agencyDefault ? "col-span-12 sm:col-span-6" : "col-span-12 sm:col-span-9")}>
                                                                        <div className="w-full mb-4 border-b">
                                                                            <div className="text-sm font-bold pb-1 text-gray-500">Linked feed</div>
                                                                        </div>
                                                                        <div className="grid grid-cols-12 gap-4 w-full">
                                                                            <div className="col-span-12 sm:col-span-6">
                                                                                <div className="relative">
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            if (!this.state.proptree.agency) {
                                                                                                this.state.proptree.agency = {};
                                                                                            }
                                                                                            this.state.proptree.agency.showFeedBanner = option;
                                                                                            await this.promisedSetState({
                                                                                                proptree: this.state.proptree,
                                                                                                select_all: false
                                                                                            });
                                                                                        }}
                                                                                        selected={this.state.proptree.agency && this.state.proptree.agency.showFeedBanner ? this.state.proptree.agency.showFeedBanner : { id: 1, name: 'Disabled', value: 'disabled' }}
                                                                                        options={[
                                                                                            { id: 1, name: 'Disabled', value: 'disabled' },
                                                                                            { id: 2, name: 'Enabled', value: 'enabled' }
                                                                                        ]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.proptree.agency && this.state.proptree.agency.showFeedBanner && this.state.proptree.agency.showFeedBanner.value == 'enabled' &&
                                                                                <div className="col-span-12 sm:col-span-6">
                                                                                    <div className="relative">
                                                                                        <SingleSelection
                                                                                            loading={this.state.loading_forms}
                                                                                            select={async (option) => {
                                                                                                if (!this.state.proptree.agency) {
                                                                                                    this.state.proptree.agency = {};
                                                                                                }
                                                                                                this.state.proptree.agency.externalFeed = option;
                                                                                                await this.promisedSetState({
                                                                                                    proptree: this.state.proptree
                                                                                                });
                                                                                            }}
                                                                                            selected={this.state.proptree.agency && this.state.proptree.agency.externalFeed ? this.state.proptree.agency.externalFeed : { id: 0, name: "Click to Select" }}
                                                                                            options={this.state.other_forms}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            this.state.tab.value == 'agents' &&
                                                            <div className="px-4 pb-5 grid-cols-12 grid gap-5 sm:px-6">
                                                                {
                                                                    <div className="col-span-12 sm:col-span-3">
                                                                        <div className="w-full mb-5 border-b">
                                                                            <div className="text-sm font-bold pb-1 text-gray-500">Form</div>
                                                                        </div>
                                                                        <div className="w-full">
                                                                            {
                                                                                false &&
                                                                                <label htmlFor="proptree-banner" className="block text-sm font-medium text-gray-900">
                                                                                    Form
                                                                                </label>
                                                                            }
                                                                            <div className="relative">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.feed = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                    }}
                                                                                    disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.feed ? this.state.proptree.feed : { id: 0, name: 'Click to Select' }}
                                                                                    options={this.state.feeds}
                                                                                    name="proptree-feed"
                                                                                    id="proptree-feed"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="col-span-12 sm:col-span-3">
                                                                        <div className="w-full mb-5 border-b">
                                                                            <div className="text-sm font-bold pb-1 text-gray-500">Agency banner</div>
                                                                        </div>
                                                                        <div className="w-full">
                                                                            {
                                                                                false &&
                                                                                <label htmlFor="proptree-banner" className="block text-sm font-medium text-gray-900">
                                                                                    Display
                                                                                </label>
                                                                            }
                                                                            <div className="relative">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        if (!this.state.proptree.agents) {
                                                                                            this.state.proptree.agents = {};
                                                                                        }
                                                                                        this.state.proptree.agents.showAgencyBanner = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree,
                                                                                            select_all: false
                                                                                        });
                                                                                    }}
                                                                                    selected={this.state.proptree.agents && this.state.proptree.agents.showAgencyBanner ? this.state.proptree.agents.showAgencyBanner : { id: 2, name: 'Enabled', value: 'enabled' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Disabled', value: 'disabled' },
                                                                                        { id: 2, name: 'Enabled', value: 'enabled' }
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.other_forms && this.state.other_forms.length > 0 && this.state.proptree.agencyDefault &&
                                                                    <div className={(this.state.proptree.agencyDefault ? "col-span-12 sm:col-span-6" : "col-span-12")}>
                                                                        <div className="w-full mb-4 border-b">
                                                                            <div className="text-sm font-bold pb-1 text-gray-500">Linked feed</div>
                                                                        </div>
                                                                        <div className="grid grid-cols-12 gap-4 w-full">
                                                                            <div className="col-span-12 sm:col-span-6">
                                                                                {
                                                                                    false &&
                                                                                    <label htmlFor="proptree-banner" className="block text-sm font-medium text-gray-900">
                                                                                        Display
                                                                                    </label>
                                                                                }
                                                                                <div className="relative">
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            if (!this.state.proptree.agents) {
                                                                                                this.state.proptree.agents = {};
                                                                                            }
                                                                                            this.state.proptree.agents.showFeedBanner = option;
                                                                                            await this.promisedSetState({
                                                                                                proptree: this.state.proptree,
                                                                                                select_all: false
                                                                                            });
                                                                                        }}
                                                                                        selected={this.state.proptree.agents && this.state.proptree.agents.showFeedBanner ? this.state.proptree.agents.showFeedBanner : { id: 1, name: 'Disabled', value: 'disabled' }}
                                                                                        options={[
                                                                                            { id: 1, name: 'Disabled', value: 'disabled' },
                                                                                            { id: 2, name: 'Enabled', value: 'enabled' }
                                                                                        ]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.proptree.agents && this.state.proptree.agents.showFeedBanner && this.state.proptree.agents.showFeedBanner.value == 'enabled' &&
                                                                                <div className="col-span-12 sm:col-span-6">
                                                                                    {
                                                                                        false &&
                                                                                        <label htmlFor="proptree-banner-type" className="block text-sm font-medium text-gray-900">
                                                                                            Affiliate
                                                                                        </label>
                                                                                    }
                                                                                    <div className="relative">
                                                                                        <SingleSelection
                                                                                            loading={this.state.loading_forms}
                                                                                            select={async (option) => {
                                                                                                if (!this.state.proptree.agents) {
                                                                                                    this.state.proptree.agents = {};
                                                                                                }
                                                                                                this.state.proptree.agents.externalFeed = option;
                                                                                                await this.promisedSetState({
                                                                                                    proptree: this.state.proptree
                                                                                                });
                                                                                            }}
                                                                                            selected={this.state.proptree.agents && this.state.proptree.agents.externalFeed ? this.state.proptree.agents.externalFeed : { id: 0, name: "Click to Select" }}
                                                                                            options={this.state.other_forms}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.proptree.agents && this.state.proptree.agents.showFeedBanner && this.state.proptree.agents.showFeedBanner.value == 'enabled' &&
                                                                                <div className="col-span-12 sm:col-span-6">
                                                                                    {
                                                                                        false &&
                                                                                        <label htmlFor="proptree-banner" className="block text-sm font-medium text-gray-900">
                                                                                            Only display agents listings
                                                                                        </label>
                                                                                    }
                                                                                    <div className="relative">
                                                                                        <SingleSelection
                                                                                            select={async (option) => {
                                                                                                if (!this.state.proptree.agents) {
                                                                                                    this.state.proptree.agents = {};
                                                                                                }
                                                                                                this.state.proptree.agents.filterAgent = option;
                                                                                                await this.promisedSetState({
                                                                                                    proptree: this.state.proptree,
                                                                                                    select_all: false
                                                                                                });
                                                                                            }}
                                                                                            selected={this.state.proptree.agents && this.state.proptree.agents.filterAgent ? this.state.proptree.agents.filterAgent : { id: 1, name: 'All listings', value: "disabled" }}
                                                                                            options={[
                                                                                                { id: 1, name: 'All listings', value: 'disabled' },
                                                                                                { id: 2, name: 'Agents listings', value: 'enabled' }
                                                                                            ]}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }

                                                        {/* LISTINGS FILTER */}
                                                        <div className="px-4 pb-5 grid-cols-12 grid gap-5 sm:px-6">
                                                            <div className="w-full col-span-12 border-b">
                                                                <div className="text-sm font-bold pb-1 text-gray-500">Listing filters</div>
                                                            </div>
                                                        </div>
                                                        <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                            {
                                                                <div className="col-span-12 sm:col-span-3 relative">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="proptree-main-feed" className="block text-sm font-medium text-gray-900">
                                                                                Main feed
                                                                            </label>
                                                                            <div className="mt-1 relative">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.mainFeed = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                    }}
                                                                                    disabled={this.state.tab.value == 'agents'}
                                                                                    selected={this.state.proptree.mainFeed ? this.state.proptree.mainFeed : { id: 1, name: 'Enabled', value: "enabled" }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Enabled', value: "enabled" },
                                                                                        { id: 2, name: 'Disabled', value: "disabled" }
                                                                                    ]}
                                                                                    name="proptree-main-feed"
                                                                                    id="proptree-main-feed"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-span-12 sm:col-span-3 relative">
                                                                {
                                                                    !(this.state.proptree.feed && this.state.proptree.feed.id) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="proptree-feed-type" className="block text-sm font-medium text-gray-900">
                                                                            Type
                                                                        </label>
                                                                        <div className="mt-1 relative">
                                                                            {
                                                                                this.state.loading_listings &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.propertiesType = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.tab.value == 'agents'}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.propertiesType ? this.state.proptree.propertiesType : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Sale & Rentals', value: "both" },
                                                                                        { id: 2, name: 'Sale', value: "sale" },
                                                                                        { id: 3, name: 'Rentals', value: "rent" }
                                                                                    ]}
                                                                                    name="proptree-feed-type"
                                                                                    id="proptree-feed-type"
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.propertiesType = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.propertiesType ? this.state.proptree.propertiesType : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 2, name: 'Sale', value: "sale" }
                                                                                    ]}
                                                                                    name="proptree-feed-type"
                                                                                    id="proptree-feed-type"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-12 sm:col-span-3 relative">
                                                                {
                                                                    !(this.state.proptree.propertiesType && this.state.proptree.propertiesType.value) &&
                                                                    <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                }
                                                                <div className="space-y-6">
                                                                    <div>
                                                                        <label htmlFor="proptree-feed-plan" className="block text-sm font-medium text-gray-900">
                                                                            Plan
                                                                        </label>
                                                                        <div className="mt-1 relative">
                                                                            {
                                                                                this.state.loading_listings &&
                                                                                <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !(this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.property = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree,
                                                                                            select_all: false
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    disabled={this.state.tab.value == 'agents'}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.property ? this.state.proptree.property : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'On-plan & Off-plan', value: "both" },
                                                                                        { id: 2, name: 'On-plan', value: 'on_plan' },
                                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                    ]}
                                                                                    name="proptree-feed-plan"
                                                                                    id="proptree-feed-plan"
                                                                                />
                                                                            }
                                                                            {
                                                                                this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 &&
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.property = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree,
                                                                                            select_all: false
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.property ? this.state.proptree.property : { id: 0, name: 'Click to Select' }}
                                                                                    options={[
                                                                                        { id: 3, name: 'Off-plan', value: 'off_plan' }
                                                                                    ]}
                                                                                    name="proptree-feed-plan"
                                                                                    id="proptree-feed-plan"
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.proptree.agencyDefault &&
                                                                this.state.tab.value == 'agents' &&
                                                                <div className="col-span-12 sm:col-span-3 relative">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="proptree-feed-type" className="block text-sm font-medium text-gray-900">
                                                                                Agency listings
                                                                            </label>
                                                                            <div className="mt-1 relative">
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.showAllAgencyListings = option;
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree
                                                                                        });
                                                                                    }}
                                                                                    selected={this.state.proptree.showAllAgencyListings ? this.state.proptree.showAllAgencyListings : { id: 1, name: 'Disabled', value: "disabled" }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Disabled', value: "disabled" },
                                                                                        { id: 2, name: 'All listings', value: "enabled" }
                                                                                    ]}
                                                                                    name="proptree-feed-type"
                                                                                    id="proptree-feed-type"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                !this.state.proptree.agencyDefault &&
                                                                <div className="col-span-12 sm:col-span-3 relative">
                                                                    {
                                                                        !(this.state.proptree.property && this.state.proptree.property.value) &&
                                                                        <div className="bg-white opacity-75 cursor-not-allowed absolute z-5 left-0 right-0 top-0 bottom-0"></div>
                                                                    }
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="proptree-feed-filter" className="block text-sm font-medium text-gray-900">
                                                                                Listings
                                                                            </label>
                                                                            <div className="mt-1 relative">
                                                                                {
                                                                                    this.state.loading_listings &&
                                                                                    <div className="w-full h-full absolute z-50 bg-white bg-opacity-75 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-indigo-900 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                                <SingleSelection
                                                                                    select={async (option) => {
                                                                                        this.state.proptree.chooseListings = option;
                                                                                        this.state.proptree.listings = {};
                                                                                        await this.promisedSetState({
                                                                                            proptree: this.state.proptree,
                                                                                            select_all: false
                                                                                        });
                                                                                        if (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") {
                                                                                            this.functions.getListings();
                                                                                        }
                                                                                    }}
                                                                                    //disabled={this.state.proptree.id}
                                                                                    selected={this.state.proptree.chooseListings ? this.state.proptree.chooseListings : { id: 1, name: 'All', value: "disabled" }}
                                                                                    options={[
                                                                                        { id: 1, name: 'All', value: 'disabled' },
                                                                                        { id: 2, name: 'Specific', value: 'enabled' }
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        {/* OTHER SETTINGS */}
                                                        {
                                                            this.state.proptree.agencyDefault &&
                                                            this.state.tab.value == 'agency' &&
                                                            <div className="px-4 pt-5 pb-5 grid-cols-12 grid gap-5 sm:px-6">
                                                                <div className="w-full col-span-12 border-b">
                                                                    <div className="text-sm font-bold pb-1 text-gray-500">Other settings</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.proptree.agencyDefault &&
                                                            this.state.tab.value == 'agency' &&
                                                            <div className="px-4 grid-cols-12 grid gap-5 sm:px-6">
                                                                {
                                                                    <div className="col-span-12 sm:col-span-3 relative">
                                                                        <div className="space-y-6">
                                                                            <div>
                                                                                <label htmlFor="proptree-agent-feed" className="block text-sm font-medium text-gray-900">
                                                                                    Agent feed
                                                                                </label>
                                                                                <div className="mt-1 relative">
                                                                                    <SingleSelection
                                                                                        select={async (option) => {
                                                                                            if (!this.state.proptree.agency) {
                                                                                                this.state.proptree.agency = {};
                                                                                            }
                                                                                            this.state.proptree.agency.showAgentFeed = option;
                                                                                            await this.promisedSetState({
                                                                                                proptree: this.state.proptree,
                                                                                                select_all: false
                                                                                            });
                                                                                        }}
                                                                                        selected={this.state.proptree.agency && this.state.proptree.agency.showAgentFeed ? this.state.proptree.agency.showAgentFeed : { id: 1, name: 'Enabled', value: 'enabled' }}
                                                                                        options={[
                                                                                            { id: 1, name: 'Enabled', value: 'enabled' },
                                                                                            { id: 2, name: 'Disabled', value: 'disabled' }
                                                                                        ]}
                                                                                        name="proptree-agent-feed"
                                                                                        id="proptree-agent-feed"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }

                                                        {
                                                            (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") &&
                                                            !this.state.loading_listings &&
                                                            this.state.listings.length > 0 && !this.state.proptree.agencyDefault &&
                                                            <div className="px-4 w-full sm:px-6">
                                                                <div className="w-full border-b">
                                                                    <div className="text-sm font-bold pb-1 text-gray-500">Select listings</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") &&
                                                            !this.state.loading_listings &&
                                                            this.state.listings.length > 0 && !this.state.proptree.agencyDefault &&
                                                            <div className="w-full sm:px-6 px-4 mt-4 flex items-center">
                                                                <div className="">
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="mr-4 text-sm font-medium">Select all ({this.state.total_listings})</div>
                                                                        <div onClick={async () => {
                                                                            await this.promisedSetState({
                                                                                proptree: this.state.proptree,
                                                                                select_all: !this.state.select_all
                                                                            });
                                                                            if (!this.state.select_all) {
                                                                                this.state.proptree.listings = {};
                                                                            } else {
                                                                                this.state.proptree.listings = {};
                                                                                this.state.listings.map((item) => {
                                                                                    this.state.proptree.listings[item.externalId] = true;
                                                                                });
                                                                            }
                                                                            await this.promisedSetState({
                                                                                proptree: this.state.proptree
                                                                            });
                                                                        }} style={{ height: "39px", width: "39px" }} className={(this.state.select_all ? "bg-purple-500" : "") + " border cursor-pointer flex items-center justify-center rounded-md bg-gray-50"}>
                                                                            {this.state.select_all && <CheckIcon className="w-5 text-white"></CheckIcon>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-1 ml-2">
                                                                    <div className="w-full">
                                                                        <input
                                                                            style={window.innerWidth < 640 ? { height: "39px" } : {}}
                                                                            onChange={async (event) => {
                                                                                await this.promisedSetState({
                                                                                    search_listings: event.target.value
                                                                                })
                                                                            }}
                                                                            placeholder={"Search listings"}
                                                                            value={this.state.search_listings}
                                                                            type="text"
                                                                            name="proptree-feed-search"
                                                                            id="proptree-feed-search"
                                                                            className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            (this.state.proptree.chooseListings && this.state.proptree.chooseListings.value == "enabled") &&
                                                            !this.state.loading_listings &&
                                                            this.state.listings.length > 0 && !this.state.proptree.agencyDefault &&
                                                            <div className="w-full sm:px-6 mb-4 px-4 mt-4">
                                                                <div className="flex flex-col border rounded-md p-2 pt-0 bg-gray-50">
                                                                    {this.state.listings.map((item) => {
                                                                        return (
                                                                            <div>
                                                                                <div onClick={() => {
                                                                                    if (!this.state.proptree.listings) {
                                                                                        this.state.proptree.listings = {};
                                                                                    }
                                                                                    if (this.state.proptree.listings[item.externalId]) {
                                                                                        delete this.state.proptree.listings[item.externalId];
                                                                                    } else {
                                                                                        this.state.proptree.listings[item.externalId] = true;
                                                                                    }
                                                                                    this.setState({
                                                                                        proptree: this.state.proptree
                                                                                    });
                                                                                }} className="w-full mt-2 flex flex-row items-center mr-4">
                                                                                    <div style={{ minHeight: "39px", minWidth: "39px" }} className={(this.state.proptree.listings && this.state.proptree.listings[item.externalId] ? "bg-purple-500" : "") + " border cursor-pointer flex items-center justify-center rounded-md bg-white"}>
                                                                                        {this.state.proptree.listings && this.state.proptree.listings[item.externalId] && <CheckIcon className="w-5 text-white"></CheckIcon>}
                                                                                    </div>
                                                                                    <div className="ml-2 sm:text-xs text-xxs font-medium flex justify-content items-center flex-row">{item.imagesInternal && item.imagesInternal[0] ? (
                                                                                        <img
                                                                                            style={{ height: "39px", width: "39px" }}
                                                                                            className="rounded-md mr-2"
                                                                                            src={item.imagesInternal[0]}
                                                                                            alt=""
                                                                                        />
                                                                                    ) : (
                                                                                        <svg
                                                                                            className="border rounded-md mr-2 text-gray-300"
                                                                                            style={{ height: "39px", width: "39px" }}
                                                                                            viewBox="0 0 24 20"
                                                                                            fill="none"
                                                                                        >
                                                                                            <path
                                                                                                d="M19.0316 0L19.9614 5.43362H24V20.2812H4.67806V18.9156L2.78923 19.2351L0 3.34155L19.0316 0ZM4.06803 17.4332L4.67817 17.3172V5.43339H18.3927L17.7533 1.80113L1.8017 4.61979L4.06803 17.4332ZM6.247 18.7119H22.4312V7.00255H6.247V18.7119ZM7.29291 16.7359V8.04847H21.3849V14.3535L18.1889 10.6632L17.5494 10.4018L12.6101 15.8352L10.5763 14.557L7.29291 16.7359ZM9.03633 10.4018C9.03633 11.1574 9.67568 11.7965 10.4309 11.7965C11.1864 11.7965 11.8257 11.1574 11.8254 10.4018C11.8254 9.64602 11.1864 9.00691 10.4309 9.00691C9.67568 9.00691 9.03633 9.64637 9.03633 10.4018Z"
                                                                                                fill="currentColor"
                                                                                            />
                                                                                        </svg>
                                                                                    )}<div className="flex flex-col"><div>{this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1 ? item.projectName : item.adboosterIndustry.name}</div><div className="text-gray-500">{"#" + item.externalId}</div></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    this.state.error &&
                                                    <div className="px-6 pb-6">
                                                        <div className="rounded-md bg-red-100 bg-opacity-50 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                    <div className="mt-2 text-sm text-red-700">
                                                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                                                            <li>{this.state.error}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex-shrink-0 px-4 sm:px-6 py-4 flex justify-end">
                                                {
                                                    !this.state.dontShowSubmitButton &&
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.proptree.id) {
                                                                if (this.renders.valid()) {
                                                                    this.functions.updateProptree();
                                                                }
                                                            } else {
                                                                if (this.renders.valid()) {
                                                                    this.functions.createProptree();
                                                                }
                                                            }
                                                        }}
                                                        className={(this.renders.valid() ? "bg-purple-500 hover:bg-purple-600 cursor-pointer" : "bg-gray-200 cursor-not-allowed") + " ml-4 inline-flex relative justify-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                    >
                                                        {
                                                            this.state.loading &&
                                                            <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        {
                                                            !this.state.proptree.id &&
                                                            <span>Create</span>
                                                        }
                                                        {
                                                            this.state.proptree.id &&
                                                            <span>Update</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CreateNewProptree;
