import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class CreateEnterprise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            open: false,
            enterprises: [],
            error: "",
            user: {},
            ad: {}
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            user: this.props.user
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.open && nextProps.open) {
            this.setState({
                name: "",
                open: false,
                user: nextProps.user
            });
        }
        this.setState({
            open: nextProps.open
        })
    }

    functions = {
        createAd: async () => {
            try {
                let response = {};
                if (this.state.ad.type && this.state.ad.type.id === 1) {
                    response = await this.calls.createFeedAd(this.state.ad);
                }
                this.setState({
                    loading: false
                }, () => {
                    this.props.toggle();
                    if (this.props.onAd) {
                        this.props.onAd(response.data);
                    }
                })
            } catch (error) {
                this.setState({
                    loading: false
                })
            }
        },
        uploadImage: (e) => {
            if (e.target.files) {
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                this.setState({
                    loading_image: true
                }, () => {
                    this.calls.uploadImage(formData).then((response) => {
                        this.state.user.image = response.data;
                        this.setState({
                            user: this.state.user,
                            loading_image: false
                        })
                    }, (error) => {
                        this.setState({
                            loading_image: false
                        })
                    });
                });
            }
        },
        getEnterprise: async () => {
            try {
                let response = await this.calls.getEnterprise();
                this.setState({
                    enterprises: response.data,
                    loading_enterprise: false
                })
            } catch (error) {
                this.setState({
                    loading_enterprise: false
                })
            }
        },
    };

    calls = {
        createFeedAd: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/ads/create-feed";
            return apiRegister.call(options, url);
        },
        uploadImage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/media/image";
            return apiRegister.call(options, url);
        },
        getEnterprise: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/enterprise/list";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">
                        
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 pl-10 max-w-full right-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={(this.state.type && this.state.type.id === 2 ? "max-w-4xl" : "max-w-md") + " w-screen"}>
                                    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-5 px-4 bg-indigo-700 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-md font-medium text-white">New Ad</Dialog.Title>
                                                    <div className="ml-3 h-7 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-6 gap-6 pt-6 px-6">

                                                {
                                                    this.state.ad.type && this.state.ad.type.id === 2 &&
                                                    <div className="col-span-3">
                                                        <div className="pt-6 h-full px-8">
                                                            <div style={{ paddingTop: "156%", borderRadius: "20px" }} className="border-2 border-indigo-500 w-full relative">
                                                                <div className="absolute top-0 bottom-0 right-0 left-0">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className={(this.state.ad.type && this.state.ad.type.id === 2 ? "col-span-3" : "col-span-6")}>

                                                    <div className="pb-5">
                                                        <label htmlFor="ad-type" className="block text-sm font-medium text-gray-900">
                                                            Type
                                                        </label>
                                                        <div className="mt-1">
                                                            <SingleSelection
                                                                select={(option) => {
                                                                    if (option.id == 2) {
                                                                        this.state.ad.channel = { id: 1, name: 'Facebook' };
                                                                    }
                                                                    this.state.ad.type = option;
                                                                    this.setState({
                                                                        ad: this.state.ad,
                                                                    }, () => {
                                                                        if (option.id === 1 && this.state.enterprises.length < 1) {
                                                                            this.functions.getEnterprise();
                                                                        }
                                                                    })
                                                                }}
                                                                selected={this.state.ad.type ? this.state.ad.type : { id: 0, name: 'Select Type' }}
                                                                options={(this.state.user && this.state.user.level && this.state.user.level.id == 1 ? [{ id: 1, name: 'Feed' }, { id: 2, name: 'Lead' }] : [{ id: 2, name: 'Lead' }])}
                                                                name="ad-type"
                                                                id="ad-type"
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.ad.type && this.state.ad.type.id === 1 &&
                                                        <div className="pb-5">
                                                            <label htmlFor="ad-enterprise" className="block text-sm font-medium text-gray-900">
                                                                Enterprise
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.state.ad.enterprise = option;
                                                                        this.setState({
                                                                            ad: this.state.ad
                                                                        })
                                                                    }}
                                                                    selected={this.state.ad.enterprise ? this.state.ad.enterprise : { id: 0, name: "Select Enterprise" }}
                                                                    options={this.state.enterprises}
                                                                    name="ad-enterprise"
                                                                    id="ad-enterprise"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.ad.enterprise &&
                                                        <div className="pb-5">
                                                            <label htmlFor="ad-name" className="block text-sm font-medium text-gray-700">
                                                                Name
                                                            </label>
                                                            <input
                                                                value={this.state.ad.name ? this.state.ad.name : ""}
                                                                onChange={(event) => {
                                                                    this.state.ad.name = event.target.value;
                                                                    this.setState({
                                                                        ad: this.state.ad
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="ad-name"
                                                                id="ad-name"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.ad.type && ((this.state.ad.type.id === 1 && this.state.ad.enterprise) || this.state.ad.type.id === 2) &&
                                                        <div className="pb-5">
                                                            <label htmlFor="ad-channel" className="block text-sm font-medium text-gray-900">
                                                                Channel
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    disabled={this.state.ad.type && this.state.ad.type.id === 2}
                                                                    select={(option) => {
                                                                        this.state.ad.channel = option;
                                                                        this.setState({
                                                                            ad: this.state.ad
                                                                        })
                                                                    }}
                                                                    selected={this.state.ad.channel ? this.state.ad.channel : { id: 0, name: 'Select channel' }}
                                                                    options={[
                                                                        { id: 1, name: 'Facebook' },
                                                                        { id: 2, name: 'Google' }
                                                                    ]}
                                                                    name="ad-channel"
                                                                    id="ad-channel"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.ad.type && this.state.ad.type.id === 1 && this.state.ad.enterprise &&
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <label htmlFor="ad-external-id" className="block text-sm font-medium text-gray-700">
                                                                External ID
                                                            </label>
                                                            <input
                                                                value={this.state.ad.externalId ? this.state.ad.externalId : ""}
                                                                onChange={(event) => {
                                                                    this.state.ad.externalId = event.target.value;
                                                                    this.setState({
                                                                        ad: this.state.ad
                                                                    })
                                                                }}
                                                                type="text"
                                                                name="ad-external-id"
                                                                id="ad-external-id"
                                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.ad.type && this.state.ad.type.id === 2 &&
                                                        <div className="pb-5">
                                                            <label htmlFor="ad-template" className="block text-sm font-medium text-gray-900">
                                                                Template
                                                            </label>
                                                            <div className="mt-1">
                                                                <SingleSelection
                                                                    select={(option) => {
                                                                        this.setState({
                                                                            template: option
                                                                        })
                                                                    }}
                                                                    selected={this.state.template ? this.state.template : { id: 1, name: 'Image' }}
                                                                    options={[
                                                                        { id: 1, name: 'Image' },
                                                                        { id: 2, name: 'Video' },
                                                                        { id: 3, name: 'Carousel' }
                                                                    ]}
                                                                    name="ad-template"
                                                                    id="ad-template"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.type && this.state.type.id === 2 &&
                                                        <div>
                                                            <label htmlFor="ad-image" className="block text-sm font-medium text-gray-900">
                                                                Upload media
                                                            </label>
                                                            <div className="relative w-full text-center justify-center cursor-pointer bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                                                                <input
                                                                    ref="uploadImageFile"
                                                                    onClick={() => this.refs.uploadImageFile.value = null}
                                                                    onChange={(e) => {
                                                                        this.functions.uploadImage(e)
                                                                    }}
                                                                    type="file"
                                                                    accept={"image/png,image/jpg"}
                                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                                                    name="ad-image"
                                                                    id="ad-image"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            {
                                                !this.state.loading &&
                                                <button
                                                    type="button"
                                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    onClick={() => {
                                                        this.props.toggle();
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            }
                                            <button
                                                onClick={() => {
                                                    this.functions.createAd();
                                                }}
                                                className="ml-4 inline-flex relative justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                {
                                                    this.state.loading &&
                                                    <div className="w-full h-full absolute bg-indigo-600 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreateEnterprise;
