import React, { Component, Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react'
import { ClipboardListIcon, CurrencyDollarIcon, GlobeAltIcon, ChevronDownIcon, TranslateIcon, HomeIcon, TrendingUpIcon } from '@heroicons/react/outline'
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import WarningModal from '../components/warningModal';
import SingleSelection from '../components/singleSelection';
import { versionRegister } from '../services/versionRegister';
import { OfficeBuildingIcon, TrendingDownIcon } from '@heroicons/react/solid';
var moment = require('moment');

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class PreviewFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_feeds: true,
            feed: {
                data: []
            },
            feeds: [],
            user: {},
            version: {},
            view_type: { id: 2, name: "Sidescroll (images)" },
            enterprise: {},
            sort: 'lastUpdated',
            order: 'ascending',
            page: 1,
            page_count: 100,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {

            //SET USER AND VERSION
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
            let version = versionRegister.get();
            await this.promisedSetState({ version: version });

            //GET FEED
            let feed = this.props.history.location.pathname.replace("/dashboard/feeds/", "");
            if (feed !== "") {
                this.functions.getFeed(feed);
            } else {
                this.promisedSetState({
                    error: "Cant find Feed"
                })
            }

            //GET FEEDS
            let enterprise = this.props.history.location.search.replace("?enterprise=", "");
            if (enterprise !== "") {
                this.functions.getFeeds(enterprise);
            }

        },
        getFeed: async (feed) => {
            try {
                let response = await this.calls.getFeed(feed);
                response.data.data = response.data.data.filter((item, index) => { return (("name") in item || ("Listing name") in item || ("title") in item) });
                response.data.name = (response.data.language + " - " + response.data.currency + " - " + response.data.type + " - " + response.data.theme + " - " + response.data.channel);
                response.data.name = response.data.name.toLowerCase();
                await this.promisedSetState({
                    feed: response.data,
                    total: response.data.data.length,
                    loading: false
                });
                this.functions.sideScroll('table');
                this.functions.sideScroll('table2');
            } catch (error) {
                this.promisedSetState({
                    loading: false,
                    error: "Cant find Feed"
                })
            }
        },
        getFeeds: async (enterprise) => {
            try {
                let response = await this.calls.getFeeds(enterprise);
                await this.promisedSetState({
                    feeds: response.data.map((item) => { item.name = (item.language + " - " + item.currency + " - " + item.type + " - " + (item.theme ? item.theme.name : "no theme") + " - " + item.channel); return item }),
                    loading_feeds: false
                });
            } catch (error) {
                this.promisedSetState({
                    feeds: [],
                    loading_feeds: false
                })
            }
        },
        sideScroll: (id) => {
            try {
                const slider = document.querySelector('#' + id);
                let isDown = false;
                let startX;
                let scrollLeft;

                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1; //scroll-fast
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        },
        checkImageLink: (item) => {
            if (this.state.feed.channel === "facebook") {
                return item["image[0].url"];
            } else if (this.state.feed.channel === 'google') {
                return item["Image URL"];
            } else if (this.state.feed.channel === 'tiktok') {
                return item["image_link"];
            } else if (this.state.feed.channel === 'snapchat') {
                return item["image_link"];
            } else {
                return false;
            }
        }
    };

    calls = {
        getFeed: (feed) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed/" + feed;
            return apiRegister.call(options, url);
        },
        getFeeds: (enterprise) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/feed/list?enterprise=" + enterprise;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                {/*ERROR MODAL*/}
                <WarningModal
                    open={this.state.error ? true : false}
                    title={"Something went wrong"}
                    description={this.state.error}
                    cancelButtonText={"cancel"}
                    submitButtonText={"ok"}
                    onClose={() => {
                        this.setState({
                            error: null
                        })
                    }}
                    onSubmit={() => {
                        this.setState({
                            error: null
                        })
                    }}
                />

                {/*ADMIN HEADER*/}
                {
                    this.state.user && this.state.user.level && this.state.user.level.id == 1 &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center bg-orange-100 border-orange-500">
                                        <div>
                                            <ClipboardListIcon className="h-8 w-8 text-orange-500" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Feeds</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">

                        {/*LIST FEEDS*/}
                        {
                            !this.state.loading &&
                            <section>
                                <div className="bg-white shadow sm:rounded-md">

                                    <div className="flex items-center p-6 border-b">
                                        <div className="flex flex-1 flex-col">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Preview feed</h3>
                                            <p className="mt-1 mr-5 text-sm text-gray-500">#{this.state.feed.id}</p>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-2 col-gap-4 p-6 pb-0 sm:flex flex-row">
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0 sm:mr-10">
                                            <div className="text-xs text-gray-500">Language</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.language}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Currency</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.currency}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Type</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.type}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Theme</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.theme}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Custom</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.custom ? this.state.feed.custom : "disabled"}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Channel</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.channel}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Min price</div>
                                            <div className="text-sm font-medium whitespace-no-wrap lowercase">{this.state.feed.minPrice ? this.state.feed.minPrice : '-'}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Max price</div>
                                            <div className="text-sm font-medium whitespace-no-wrap lowercase">{this.state.feed.maxPrice ? this.state.feed.maxPrice : '-'}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Category</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.offPlan ? "projects" : "units"}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Plan</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.property ? this.state.feed.property : "both"}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Country code</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.dial_code ? this.state.feed.dial_code : '-'}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Size unit</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.sizeUnit ? this.state.feed.sizeUnit : 'sqft'}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Double qualify</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.doubleQualify ? this.state.feed.doubleQualify : 'inherit'}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">Utm_feed override</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.utm ? this.state.feed.utm : '-'}</div>
                                        </div>
                                        <div className="flex flex-col col-span-1 mb-4 sm:mb-0  sm:mr-10">
                                            <div className="text-xs text-gray-500">{this.state.feed.customFilterKey ? 'Custom filter (' + this.state.feed.customFilterKey + ')' : 'Custom filter'}</div>
                                            <div className="text-sm font-medium lowercase">{this.state.feed.customFilterValue ? this.state.feed.customFilterValue + (this.state.feed.customFilterInclude ? ' (incl)' : ' (excl)') : this.state.feed.customFilterKey && this.state.feed.customFilterEmpty ? (this.state.feed.customFilterInclude ? 'incl empty' : 'excl empty') : this.state.feed.customFilterKey && !this.state.feed.customFilterEmpty ? "not supported" :  "-"}</div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-6 gap-6 p-6">

                                        <div className={"col-span-6 sm:col-span-3"}>
                                            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                Feeds
                                            </label>
                                            <div className="mt-1">
                                                <SingleSelection
                                                    select={async (option) => {
                                                        //this.functions.getFeed(option);
                                                        window.location.assign(window.location.protocol + "//" + window.location.host + "/dashboard/feeds/" + option.id + window.location.search);
                                                    }}
                                                    selected={this.state.feed}
                                                    options={this.state.feeds}
                                                />
                                            </div>
                                        </div>

                                        <div className={"col-span-6 sm:col-span-3"}>
                                            <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                                                View type
                                            </label>
                                            <div className="mt-1">
                                                <SingleSelection
                                                    select={async (option) => {
                                                        await this.promisedSetState({
                                                            view_type: option
                                                        });
                                                        this.functions.sideScroll('table');
                                                        this.functions.sideScroll('table2');
                                                    }}
                                                    selected={this.state.view_type}
                                                    options={[
                                                        { id: 1, name: "Stacked view (images)" },
                                                        { id: 2, name: "Sidescroll (images)" }
                                                    ]}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="grid grid-cols-12 gap-6 w-full">
                                        {
                                            Array.isArray(this.state.feed.data) && this.state.feed.data.length > 0 &&
                                            this.state.feed.data.filter((item) => {
                                                return this.functions.checkImageLink(item);
                                            }).length > 0 &&
                                            <div className={"col-span-12"}>
                                                <div className="px-6">
                                                    <div className={this.state.view_type.id === 1 ? "overflow-hidden" : "overflow-auto"}>
                                                        <div id={"table2"} className={this.state.view_type.id === 1 ? "grid sm:grid-cols-6 sm:gap-y-6 sm:gap-x-6 grid-cols-3 gap-y-3 gap-x-3" : "overflow-auto flex flex-row"}>
                                                            {
                                                                this.state.feed.data.filter((item) => {
                                                                    return this.functions.checkImageLink(item);
                                                                }).filter((item, index) => {
                                                                    return index >= (this.state.page * this.state.page_count - this.state.page_count) && index < (this.state.page * this.state.page_count)
                                                                }).map((item, index) => {
                                                                    return (
                                                                        <div style={this.state.view_type.id === 1 ? {} : { minWidth: "240px", marginRight: "25px" }}>
                                                                            <div onClick={() => {
                                                                                this.setState({
                                                                                    feed_item: index == this.state.feed_item ? null : index
                                                                                })
                                                                            }} style={{ paddingTop: "100%" }} className={'relative block w-full overflow-hidden bg-white'}>
                                                                                <img src={this.functions.checkImageLink(item)} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" />
                                                                            </div>
                                                                            {
                                                                                false &&
                                                                                <div className="text-xxs sm:text-sm font-medium text-gray-900 mt-2">{item["home_listing_id"]}</div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className={"col-span-12"}>
                                            {
                                                Array.isArray(this.state.feed.data) && this.state.feed.data.length > 0 &&
                                                <div className="overflow-hidden">
                                                    <div className="flex flex-col">
                                                        <div className="w-full">
                                                            <div id={"table"} className="overflow-scroll">
                                                                <table className="min-w-full divide-y divide-gray-200">
                                                                    <thead className="bg-gray-100">
                                                                        <tr>
                                                                            {
                                                                                Object.keys(this.state.feed.data[0]).map((key) => {
                                                                                    return (
                                                                                        <th
                                                                                            style={{ whiteSpace: "nowrap" }}
                                                                                            scope="col"
                                                                                            className="px-6 py-3 border-r text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                                                        >
                                                                                            {key}
                                                                                        </th>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className={"bg-white divide-y divide-gray-200"}>
                                                                        {this.state.feed.data.filter((item, index) => {
                                                                            return index >= (this.state.page * this.state.page_count - this.state.page_count) && index < (this.state.page * this.state.page_count)
                                                                        }).map((item, index) => (
                                                                            <tr onClick={() => {
                                                                                this.setState({
                                                                                    feed_item: index == this.state.feed_item ? null : index
                                                                                })
                                                                            }} className={(this.state.feed_item == index ? "bg-gray-100" : "") + " cursor-pointer"} key={index}>
                                                                                {
                                                                                    Object.keys(item).map((key, index) => {
                                                                                        return (
                                                                                            <td style={{ whiteSpace: "nowrap" }} className={"px-6 py-4 border-r whitespace-nowrap text-sm font-medium text-gray-900"}>{item[key]}</td>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !this.state.loading_feed &&
                                                !this.state.loading_feeds &&
                                                Array.isArray(this.state.feed.data) && this.state.feed.data.length < 1 &&
                                                <div className="w-full h-64 relative flex justify-center items-start border-t">
                                                    <div className="w-full font-semibold h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                        No rows added
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="rounded-b-md bg-gray-50">
                                        <nav
                                            className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                            aria-label="Pagination"
                                        >
                                            <div className="hidden sm:block">
                                                <p className="text-sm text-gray-700">
                                                    Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.feed.data.length ? this.state.feed.data.length : (this.state.page * this.state.page_count)}</span> of{' '}
                                                    <span className="font-medium">{this.state.total}</span> results
                                                </p>
                                            </div>
                                            <div className="flex-1 flex justify-between sm:justify-end">
                                                <Menu as="div" className="relative inline-block text-left mr-2">
                                                    <div>
                                                        <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                            Page limit: {this.state.page_count}
                                                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="py-1">
                                                                {
                                                                    [1, 10, 20, 30, 50, 100].map((item) => {
                                                                        return (
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                page_count: item,
                                                                                                page: 1
                                                                                            })
                                                                                        }}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                            'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                            this.state.page_count == item ? "text-purple-500" : ""
                                                                                        )}
                                                                                    >
                                                                                        {item}
                                                                                    </div>
                                                                                )}
                                                                            </Menu.Item>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                                <div
                                                    onClick={() => {
                                                        this.setState({
                                                            page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                        });
                                                    }}
                                                    className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                >
                                                    Previous
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                            this.setState({
                                                                page: this.state.page + 1
                                                            });
                                                        }
                                                    }}
                                                    className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                >
                                                    Next
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </section>
                        }

                        {/*LOADING*/}
                        {
                            this.state.loading &&
                            <div className="w-full h-64 relative flex justify-center items-start">
                                <div className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default PreviewFeed;
