import React, { Component, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, LockClosedIcon, SelectorIcon } from '@heroicons/react/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class SingleSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: {},
            locked: false
        }
    };

    componentDidMount() {
        this.setState({
            options: this.props.options ? this.props.options : [],
            selected: this.props.selected ? this.props.selected : {},
            disabled: this.props.disabled,
            locked: this.props.locked
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            options: nextProps.options ? nextProps.options : [],
            selected: nextProps.selected ? nextProps.selected : {},
            disabled: nextProps.disabled,
            locked: nextProps.locked
        })
    }

    renders = {
        enterpriseType: (enterprise) => {
            let string = '';
            try {
                if (enterprise.version) {
                    string += '<span class="text-sm text-indigo-500 uppercase">' + enterprise.version.name + ' • </span>';
                }
                if (enterprise.legacy && enterprise.legacy.value == "enabled") {
                    string += '<span class="text-sm text-pink-500 uppercase">SCALE</span>';
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") !== -1) {
                        string += '<span class="text-sm text-pink-500 uppercase"> PROJECTS</span>';
                    }
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") === -1) {
                        string += '<span class="text-sm text-pink-500 uppercase"> UNITS</span>';
                    }
                    if (enterprise.basicSwitch && enterprise.basicSwitch.value == "enabled") {
                        string += '<span class="text-sm text-orange-500 uppercase"> • BASIC</span>';
                    }
                    if (enterprise.creativeSwitch && enterprise.creativeSwitch.value == "enabled") {
                        string += '<span class="text-sm text-yellow-600 uppercase"> • CREATIVE</span>';
                    }
                    if (enterprise.proptreeSwitch && enterprise.proptreeSwitch.value == "enabled") {
                        string += '<span class="text-sm text-purple-500 uppercase"> • PROPTREE</span>';
                    }
                } else {
                    string += '<span class="text-sm text-indigo-500 uppercase">LEGACY</span>';
                }
            } catch (error) { }
            return string;
        }
    };

    render() {
        return (
            <>
                <Listbox disabled={this.state.disabled} value={this.state.selected} onChange={(value) => {
                    this.props.select(value);
                }}>
                    {({ open }) => (
                        <>
                            <div className="mt-1 relative">
                                <Listbox.Button className={classNames(
                                    this.state.disabled ? 'bg-gray-100' : 'bg-white',
                                    'relative w-full border cursor-pointer border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none sm:text-sm'
                                )}>
                                    <div className="flex items-center">
                                        {
                                            this.state.selected.image &&
                                            <img src={this.state.selected.image} alt="" className="flex-shrink-0 h-5 w-5 rounded-full mr-3" />
                                        }
                                        {
                                            this.state.selected.channel && (this.state.selected.channel.value === 'facebook' || this.state.selected.channel.value === 'meta') &&
                                            <div className="flex-shrink-0 bg-facebook-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_meta.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && this.state.selected.channel.value === 'google' &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_google.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && this.state.selected.channel.value === 'tiktok' &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_tiktok.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && this.state.selected.channel.value === 'snapchat' &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_snapchat.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && (this.state.selected.channel.value === '' || this.state.selected.channel.value === 'internal' || !this.state.selected.channel.value) &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_internal.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected.channel && this.state.selected.channel.value === 'proptree' &&
                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                <div>
                                                    <img src={require('../assets/images/AB_Social_proptree.png')} alt="" className="w-2" />
                                                </div>
                                            </div>
                                        }
                                        <span className="block truncate">
                                            {this.state.selected.name}
                                        </span>
                                        {
                                            this.props.showTag && this.state.selected.tag &&
                                            <span className="text-xxs p-1 ml-2 bg-purple-100 rounded-md text-purple-500">{this.state.selected.tag}</span>
                                        }
                                        {
                                            this.state.selected.version &&
                                            <span className={'text-indigo-500 ml-1'}>
                                                <span className={'sm:truncate'} dangerouslySetInnerHTML={{ __html: this.renders.enterpriseType(this.state.selected) }}></span>
                                            </span>
                                        }
                                    </div>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        {
                                            !this.props.icon && !this.state.locked &&
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        }
                                        {
                                            !this.props.icon && this.state.locked &&
                                            <LockClosedIcon className="h-5 w-5 text-gray-200" aria-hidden="true" />
                                        }
                                        {
                                            this.props.icon &&
                                            <this.props.icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        }
                                    </span>
                                </Listbox.Button>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white border shadow-lg max-h-40 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-y-scroll focus:outline-none sm:text-sm">
                                        {this.state.options.map((option) => (
                                            <Listbox.Option
                                                key={option.id}
                                                className={({ active }) =>
                                                    classNames(
                                                        'cursor-pointer hover:text-gray hover:bg-indigo-100 text-gray-900 select-none relative py-2 pl-3 pr-9'
                                                    )}
                                                value={option}
                                            >
                                                <>
                                                    <div className="flex items-center">
                                                        {
                                                            option.image &&
                                                            <img src={option.image} alt="" className="flex-shrink-0 h-5 w-5 rounded-full mr-3" />
                                                        }
                                                        {
                                                            !option.image && false &&
                                                            <span className="flex-shrink-0 h-5 w-5 rounded-full mr-3">
                                                                <svg className="h-full w-full text-gray-300" width="24" height="20" viewBox="0 0 24 20" fill="none">
                                                                 <path d="M19.0316 0L19.9614 5.43362H24V20.2812H4.67806V18.9156L2.78923 19.2351L0 3.34155L19.0316 0ZM4.06803 17.4332L4.67817 17.3172V5.43339H18.3927L17.7533 1.80113L1.8017 4.61979L4.06803 17.4332ZM6.247 18.7119H22.4312V7.00255H6.247V18.7119ZM7.29291 16.7359V8.04847H21.3849V14.3535L18.1889 10.6632L17.5494 10.4018L12.6101 15.8352L10.5763 14.557L7.29291 16.7359ZM9.03633 10.4018C9.03633 11.1574 9.67568 11.7965 10.4309 11.7965C11.1864 11.7965 11.8257 11.1574 11.8254 10.4018C11.8254 9.64602 11.1864 9.00691 10.4309 9.00691C9.67568 9.00691 9.03633 9.64637 9.03633 10.4018Z" fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                                        }
                                                        {
                                                            option.channel && (option.channel.value === 'facebook' || option.channel.value === 'meta') &&
                                                            <div className="flex-shrink-0 bg-facebook-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_meta.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && option.channel.value === 'google' &&
                                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_google.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && option.channel.value === 'tiktok' &&
                                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_tiktok.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && option.channel.value === 'snapchat' &&
                                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_snapchat.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && (option.channel.value === '' || option.channel.value === 'internal' || !option.channel.value) &&
                                                            <div className="flex-shrink-0 bg-facebook-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_internal.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            option.channel && option.channel.value === 'proptree' &&
                                                            <div className="flex-shrink-0 bg-google-500 h-5 w-5 rounded-full mr-3 flex justify-center items-center">
                                                                <div>
                                                                    <img src={require('../assets/images/AB_Social_proptree.png')} alt="" className="w-2" />
                                                                </div>
                                                            </div>
                                                        }
                                                        <span className={classNames(this.state.selected.id == option.id ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                            {option.name}
                                                        </span>
                                                        {
                                                            this.props.showTag && option.tag &&
                                                            <span className="text-xxs p-1 ml-2 bg-purple-100 rounded-md text-purple-500">{option.tag}</span>
                                                        }
                                                        {
                                                            option.version &&
                                                            <span className={'block truncate text-indigo-500 ml-1'}>
                                                                <span dangerouslySetInnerHTML={{ __html: this.renders.enterpriseType(option) }}></span>
                                                            </span>
                                                        }
                                                    </div>
                                                    {
                                                        this.state.selected.id && this.state.selected.id === option.id ? (
                                                        <span
                                                            className={classNames(
                                                                ' text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null
                                                    }
                                                </>
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
            </>
        )
    }
}

export default SingleSelection;
