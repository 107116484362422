import React, { Component, Fragment } from 'react';
import { DownloadIcon, PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon, UserIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import moment from 'moment';
import { userRegister } from '../../services/userRegister';
import { Bar } from 'react-chartjs-2';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class Opportunities extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tab: { id: 1, name: "This month", value: 'current_month' },
            report: {},
            user: {}
        }
    };

    componentDidMount() {
        this.functions.getUser();
        this.functions.getReport();
    }

    functions = {
        getUser: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get() ? userRegister.get() : {}
                })
            }
        },
        getReport: async () => {
            try {
                let response = await this.calls.stats();
                await this.promisedSetState({
                    stats: response.data
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading: false
            });
        },
        getLeads: async (client) => {
            if (!Array.isArray(client.list_leads)) {
                client.loading = true;
                await this.promisedSetState({
                    stats: this.state.stats
                });
                try {
                    let response = await this.calls.getLeads((client.id ? client.id : client._id));
                    response.data = response.data.map((item) => {
                        if (!item.listing.id && item.archived.id) {
                            item.listing = JSON.parse(JSON.stringify(item.archived));
                            item.listing.archived = true;
                            delete item.archived;
                        }
                        return item;
                    });
                    client.list_leads = response.data;
                    await this.promisedSetState({
                        stats: this.state.stats
                    });
                } catch (error) {
                    console.log(error);
                }
                client.loading = false;
                await this.promisedSetState({
                    stats: this.state.stats
                });
            }
        },
        barChart: (client) => {
            let graph_data = {
                labels: [],
                datasets: [
                    {
                        label: 'Price',
                        data: [],
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.7)'
                    }
                ]
            };
            if (Array.isArray(client.list_leads)) {
                client.list_leads.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                }).map((lead) => {
                    if (lead.listing) {
                        graph_data.labels.push([lead.listing.name, (" " + moment(lead.createdAt).format("YYYY-MM-DD hh:mm a"))]);
                        graph_data.datasets[0].data.push(lead.listing.price);
                    }
                });
            }
            return graph_data;
        }
    };

    calls = {
        stats: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/opportunities/getStats";
            return apiRegister.call(options, url);
        },
        getLeads: (client) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/lead/listLeads?orderBy=descending&sortBy=createdAt" + (client ? ("&client=" + client) : "");
            return apiRegister.call(options, url);
        }
    };

    renders = {
        contact: (item) => {
            if (typeof item.name == 'string' && item.name.trim() !== '') {
                return { label: 'Name', value: item.name }
            } else if (typeof item.email == 'string' && item.email.trim() !== '') {
                return { label: 'Email', value: item.email }
            } else if (typeof item.phone == 'string' && item.phone.trim() !== '') {
                return { label: 'Phone', value: item.phone }
            } else {
                return { label: 'Name', value: "-" }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 h-full min-h-full bg-gray-50">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/*DATA*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12 grid gap-4 grid-cols-12">

                        {
                            this.state.stats.enterprise_impressions > 0 && this.state.stats.portal_impression &&
                            <div className="col-span-12">
                                <div className="bg-white border shadow-lg rounded-lg">
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            Portal visitor share (previous month)
                                        </div>
                                    </div>
                                    <div className="h-100 flex justify-center items-center">
                                        <div className="relative rounded-xl overflow-auto p-8">
                                            <div className="flex justify-center relative items-center text-white text-sm font-bold leading-6">
                                                <div
                                                    style={{
                                                        minWidth: "250px",
                                                        height: "250px"
                                                    }}
                                                    className="rounded-full flex items-center flex-col text-center justify-center z-30 bg-gray-400">
                                                    <div className="font font-medium text-xxs">Portals</div>
                                                    <div>{this.state.stats.portal_impression}</div>
                                                    <div className="font font-medium text-xxs">unique visitors</div>
                                                </div>
                                                <div
                                                    style={{
                                                        marginLeft: '-' + ((((+this.state.stats.enterprise_impressions / 2) / +this.state.stats.portal_impression) * 100) * (0.9 + (1 - ((+this.state.stats.enterprise_impressions / 2) / +this.state.stats.portal_impression)))) + 'px',
                                                        minWidth: ((((+this.state.stats.enterprise_impressions / 2) / +this.state.stats.portal_impression) * 250) * (0.9 + (1 - ((+this.state.stats.enterprise_impressions / 2) / +this.state.stats.portal_impression)))) + "px",
                                                        height: ((((+this.state.stats.enterprise_impressions / 2) / +this.state.stats.portal_impression) * 250) * (0.9 + (1 - ((+this.state.stats.enterprise_impressions / 2) / +this.state.stats.portal_impression)))) + "px",
                                                    }}
                                                    className="rounded-full flex flex-col items-center text-center justify-center z-40 bg-purple-500 bg-opacity-50">
                                                    <div className="font font-medium text-xxs">{this.state.stats.name}</div>
                                                    <div>{(+this.state.stats.enterprise_impressions / 2)}</div>
                                                    <div className="font font-medium text-xxs">reach</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            [{ value: "clients", title: "Unqualified leads with high intent" }, { value: "companies", title: "Companies showing frequent interest" }].filter((item) => {
                                return Array.isArray(this.state.stats[item.value]) && this.state.stats[item.value].length > 0
                            }).map((item) => {
                                return (
                                    <div className="col-span-12 md:col-span-6">
                                        <section className="bg-white border shadow-lg rounded-lg">
                                            <div className="flex p-4 px-6 border-b border-gray-100">
                                                <div className="font-sm font-semibold flex-1 flex">
                                                    {item.title}
                                                </div>
                                            </div>
                                            {
                                                <div className="relative overflow-scroll h-130">
                                                    <table className="min-w-full">
                                                        <thead className="">
                                                            <tr>
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">Client</span>
                                                                </th>
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">Phone</span>
                                                                </th>
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">Created</span>
                                                                </th>
                                                                <th scope="col" className="">
                                                                    <span className="sr-only">Leads</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="">
                                                            {
                                                                Array.isArray(this.state.stats[item.value]) &&
                                                                this.state.stats[item.value].map((inner) => {
                                                                    if (item.value === 'companies') {
                                                                        inner.name = inner.company;
                                                                    }
                                                                    return inner;
                                                                }).map((agent, index) => {
                                                                    return (
                                                                        <Fragment>
                                                                            <tr key={agent.id} className="border-b border-gray-100">
                                                                                <td className="flex items-center px-6 py-4">
                                                                                    <span className="inline-block h-10 w-10 relative rounded-full overflow-hidden bg-gray-100">
                                                                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                        </svg>
                                                                                        {
                                                                                            agent.image !== '' &&
                                                                                            <div className="bg-cover z-5 top-0 left-0 right-0 bottom-0 absolute bg-center inline-block h-10 w-10 rounded-full overflow-hidden" style={{ backgroundImage: 'url(' + agent.image + ')' }}>

                                                                                            </div>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td className="text-center px-6 py-4">
                                                                                    <div className="flex flex-col items-center">
                                                                                        <div style={{ maxWidth: "150px" }} className="text-sm text-gray-900 truncate">{this.renders.contact(agent).value}</div>
                                                                                        <div className="text-sm text-gray-500">{this.renders.contact(agent).label}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="text-center px-6 py-4">
                                                                                    <div className="text-sm text-gray-900">{agent.unqualifiedActions}</div>
                                                                                    <div className="text-sm text-gray-500">Actions</div>
                                                                                </td>
                                                                                <td className="text-right px-6">
                                                                                    <div className="flex flex-row items-center justify-end">
                                                                                        <div>
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    agent.open = !agent.open;
                                                                                                    if (agent.open) {
                                                                                                        this.functions.getLeads(agent);
                                                                                                    }
                                                                                                    this.setState({
                                                                                                        stats: this.state.stats
                                                                                                    });
                                                                                                }}
                                                                                                className={"cursor-pointer whitespace-no-wrap mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                            >
                                                                                                {
                                                                                                    !agent.open &&
                                                                                                    <div>More info</div>
                                                                                                }
                                                                                                {
                                                                                                    agent.open &&
                                                                                                    <div>Close</div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div
                                                                                                onClick={async () => {
                                                                                                    if (this.state.user.access && this.state.user.access.id === 1) {
                                                                                                        this.props.history.push("/manager/leads?client=" + agent._id);
                                                                                                    } else {
                                                                                                        this.props.history.push("/agent/leads?client=" + agent._id);
                                                                                                    }
                                                                                                }}
                                                                                                className={"cursor-pointer whitespace-no-wrap relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                            >
                                                                                                Show leads
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr key={agent.email} className={(agent.open ? "h-100 border-b" : "h-0 overflow-hidden") + " transition-height duration-500 ease-in-out border-gray-100 relative"}>
                                                                                {
                                                                                    agent.open &&
                                                                                    <div className="absolute w-full bg-gray-50 h-100 flex justify-center items-center">
                                                                                        <div className="w-full h-full p-6 relative">
                                                                                            <div className="bg-white rounded-md w-full h-full flex flex-col">
                                                                                                <div className="p-4 w-full h-full">
                                                                                                    <Bar
                                                                                                        plugins={[
                                                                                                            /*
                                                                                                            {
                                                                                                                id: "images",
                                                                                                                afterDatasetDraw(chart, args, options) {
                                                                                                                    const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                                                                                                                    ctx.save();
                                                                                                                    console.log(chart);
                                                                                                                    let image = new Image();
                                                                                                                    image.src = "https://res.cloudinary.com/propertybooster/image/upload/c_scale,w_1280/v1669728517/live/The%20Urban%20Nest/ah7uorsn5ed9vtuixnpd.jpg";
                                                                                                                    ctx.drawImage(image, 100, 200, 100, 100);
                                                                                                                }
                                                                                                            }
                                                                                                            */
                                                                                                        ]}
                                                                                                        options={{
                                                                                                            maintainAspectRatio: false,
                                                                                                            interaction: {
                                                                                                                intersect: false,
                                                                                                            },
                                                                                                            legend: {
                                                                                                                display: false
                                                                                                            },
                                                                                                            scales: {
                                                                                                                x: {
                                                                                                                    stacked: false,
                                                                                                                },
                                                                                                                y: {
                                                                                                                    stacked: false,
                                                                                                                },
                                                                                                                xAxes: [{
                                                                                                                    ticks: {
                                                                                                                        fontSize: 8
                                                                                                                    }
                                                                                                                }],
                                                                                                                yAxes: [{
                                                                                                                    ticks: {
                                                                                                                        beginAtZero: true,
                                                                                                                        callback: function (value, index, values) {
                                                                                                                            //let currency = (this.state.user.enterprise ? this.state.user.enterprise.currency : "");
                                                                                                                            let currency = "AED";
                                                                                                                            return nFormatter(parseInt(value));
                                                                                                                            function nFormatter(num) {
                                                                                                                                if (num >= 1000000000) {
                                                                                                                                    num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                                                                                                                                }
                                                                                                                                if (num >= 1000000) {
                                                                                                                                    num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                                                                                                                }
                                                                                                                                if (num >= 1000) {
                                                                                                                                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                                                                                                                                }
                                                                                                                                return currency + " " + num;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }]
                                                                                                            },
                                                                                                        }}
                                                                                                        data={this.functions.barChart(agent)}
                                                                                                    />
                                                                                                </div>
                                                                                                {
                                                                                                    agent.loading && agent.open &&
                                                                                                    <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex justify-center items-center bg-white bg-opacity-50">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-8 h-8 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </tr>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </section>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div >
        )
    }
}

export default Opportunities;
