import React, { Component, Fragment } from 'react';
import { DownloadIcon, PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon, ChatIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import { versionRegister } from '../../services/versionRegister';
import SingleSelection from '../../components/singleSelection';
import moment from 'moment';
import CreateClient from '../../components/createClient';
import CreateLead from '../../components/createLead';
import TextArea from '../../components/textArea';
import Dropdown from '../../components/dropdown';
import { Bar } from 'react-chartjs-2';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListWhatsappMessages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            tab: { id: 3, name: "Property Booster", value: 'propertybooster', count: 0 },
            ranking: { id: 1, name: "All ratings", value: 'all' },
            type: { id: 2, name: "All messages", value: 'all' },
            users: [],
            user: {},
            listing: {},
            client: {},
            agent: {},
            sort: { id: 2, name: "Latest action", value: 'latest_action' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0,
            adbooster: 0,
            propertybooster: 0
        }
    };

    componentDidMount() {
        this.functions.getNumbers();
    }

    componentWillUnmount() { }

    functions = {
        getNumbers: async () => {
            try {
                await this.promisedSetState({ loading_users: true });
                let response = await this.calls.getNumbers();
                this.setState({
                    users: response.data,
                    total: response.meta.total ? response.meta.total : 0,
                    adbooster: response.meta.adbooster ? response.meta.adbooster : 0,
                    propertybooster: response.meta.propertybooster ? response.meta.propertybooster : 0,
                    loading_users: false,
                    loading: false
                })
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_users: false,
                    loading: false
                })
            }
        },
        getMessages: async (number, force_update) => {
            if (force_update || !Array.isArray(number.messages)) {
                number.loading = true;
                await this.promisedSetState({
                    users: this.state.users
                });
                try {
                    let response = await this.calls.getMessages(number._id);
                    number.messages = response.data;
                    await this.promisedSetState({
                        users: this.state.users
                    });
                } catch (error) {
                    console.log(error);
                }
                number.loading = false;
                await this.promisedSetState({
                    users: this.state.users
                });
            }
        },
        sendMessage: async (number, message) => {
            number.loading_message = true;
            await this.promisedSetState({
                users: this.state.users
            });
            try {
                let response = await this.calls.sendMessage({ id: number._id, message: message });
            } catch (error) {
                console.log(error);
            }
            number.message = "";
            number.loading_message = false;
            await this.promisedSetState({
                users: this.state.users
            });
        },
        barChartPriceOverTime: (client) => {
            let graph_data = {
                labels: [],
                datasets: [
                    {
                        label: 'Price',
                        data: [],
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.7)'
                    }
                ]
            };
            if (Array.isArray(client.list_leads)) {
                client.list_leads.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                }).map((lead) => {
                    if (lead.listing) {
                        graph_data.labels.push([lead.listing.name, (" " + lead.listing.reference), (" " + moment(lead.createdAt).format("YYYY-MM-DD hh:mm a"))]);
                        graph_data.datasets[0].data.push(lead.listing.price);
                    }
                });
            }
            return graph_data;
        }
    };

    calls = {
        getNumbers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/whatsappMessage/listNumbers?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&ranking=" + this.state.ranking.value + (this.state.listing.id ? ("&listing=" + this.state.listing.id) : "") + (this.state.client.id ? ("&client=" + this.state.client.id) : "") + (this.state.type.value ? ("&filter=" + this.state.type.value) : "");
            return apiRegister.call(options, url);
        },
        getMessages: (number) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/whatsappMessage/listMessages?orderBy=descending&sortBy=createdAt" + (number ? ("&number=" + encodeURIComponent(number)) : "") + (this.state.type.value ? ("&filter=" + this.state.type.value) : "") + "&type=" + this.state.tab.value;
            return apiRegister.call(options, url);
        },
        sendMessage: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/whatsappMessage/sendRegularMessage?number=" + data.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        client: (lead) => {
            if (lead.client) {
                let info = "...";
                if (lead.client.name) {
                    info = lead.client.name;
                } else if (lead.client.phone) {
                    info = lead.client.phone;
                } else if (lead.client.email) {
                    info = lead.client.email;
                }
                return info;
            } else {
                return "...";
            }
        },
        getListingUrl: (listing) => {
            if (listing.enterprise) {
                if (listing.enterprise.adboosterType && listing.enterprise.adboosterType.value === 'boostabostad') {
                    if (listing.enterprise.version && (listing.enterprise.version.id === 1 || listing.enterprise.version.value === "live")) {
                        return "https://lp.boostabostad.se/" + listing.adboosterId;
                    } else {
                        return "https://lp.devboostabostad.se/" + listing.adboosterId;
                    }
                } else {
                    if (listing.enterprise.version && (listing.enterprise.version.id === 1 || listing.enterprise.version.value === "live")) {
                        return "https://lp.propertybooster.io/" + listing.adboosterId;
                    } else {
                        return "https://lp.devpropertybooster.com/" + listing.adboosterId;
                    }
                }
            } else {
                return "-";
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={(this.props.admin ? "p-6" : "sm:p-0 p-6 pt-0 ") + " grid grid-cols-12 h-full min-h-full bg-gray-50"}>

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading messages ...</div>
                        </div>
                    </div>
                }

                {/*OPTION*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 pr-2 mb-4 md:mb-6">
                        <div className="bg-white rounded-md">
                            <Dropdown
                                selected={this.state.tab}
                                options={[
                                    //{ id: 1, name: "All numbers", value: 'total', count: this.state.total },
                                    { id: 3, name: "Property Booster", value: 'propertybooster', count: this.state.propertybooster },
                                    { id: 2, name: "adBooster", value: 'adbooster', count: this.state.adbooster },
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getNumbers();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*TYPE*/}
                {
                    false &&
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                        <div className="bg-white rounded-md">
                            <Dropdown
                                selected={this.state.type}
                                options={[
                                    { id: 1, name: "Direct messages", value: 'regular_message' },
                                    { id: 2, name: "All messages", value: 'all' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        type: option,
                                        page: 1
                                    });
                                    this.functions.getNumbers();
                                }}
                            />
                        </div>
                    </div>
                }

                <div className="col-span-0 hidden md:block md:col-span-6"></div>

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-0 pl-2 md:pl-0 md:pr-2">
                        <div className="bg-white rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 2, name: "Latest action", value: 'latest_action' },
                                    { id: 3, name: "Latest direct message", value: 'latest_direct' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getNumbers();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12 md:col-span-2 mb-4 md:mb-6 md:pl-2">
                        <div className="bg-white rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    //{ id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getNumbers();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*LIST*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white shadow-lg rounded-lg">
                                    <div className="flex p-4 px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.state.tab.name}
                                        </div>
                                    </div>
                                    {
                                        this.state.users.length < 1 &&
                                        <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_users &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">{this.state.tab.name}</span> available</div>
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.users.length > 0 &&
                                        <div className="relative overflow-x-scroll">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Listing</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Unqualified</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Pre-Qualified</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Qualified</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Actions</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Got Qualified</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Got Post Qualified</span>
                                                        </th>
                                                        <th scope="col" className="">
                                                            <span className="sr-only">Created</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.users.map((agent, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={agent.email} className="border-b border-gray-100">
                                                                        <td className="flex items-center px-6 py-4">
                                                                            <span className="inline-block h-10 w-10 relative rounded-full overflow-hidden bg-gray-100">
                                                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                </svg>
                                                                                {
                                                                                    agent.client && agent.client.image &&
                                                                                    <div className="bg-cover z-5 top-0 left-0 right-0 bottom-0 absolute bg-center inline-block h-10 w-10 rounded-full overflow-hidden" style={{ backgroundImage: 'url(' + agent.listing.image + ')' }}>

                                                                                    </div>
                                                                                }
                                                                            </span>
                                                                            <div className="ml-4 whitespace-no-wrap">
                                                                                <div style={window.innerWidth < 640 ? { maxWidth: "115px" } : {}} className="text-sm text-gray-900 whitespace-no-wrap truncate">{agent.client ? agent.client.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap hover:text-purple-500 hover:underline">
                                                                                    {agent.client ? agent.client.email : "-"}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.number ? agent.number : '-'}</div>
                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">WhatsApp number</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.createdAt ? moment(agent.createdAt).format("YYYY-MM-DD hh:mm a") : "-"}</div>
                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">Latest message</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.latest_direct_message ? moment(agent.latest_direct_message).format("YYYY-MM-DD hh:mm a") : "-"}</div>
                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">Latest direct message</div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.count ? agent.count : '-'}</div>
                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap ">Messages</div>
                                                                        </td>
                                                                        <td className="text-right px-6">
                                                                            <div
                                                                                onClick={async () => {
                                                                                    agent.open = !agent.open;
                                                                                    if (agent.open) {
                                                                                        this.functions.getMessages(agent);
                                                                                    }
                                                                                    this.setState({
                                                                                        users: this.state.users
                                                                                    });
                                                                                }}
                                                                                className={"cursor-pointer whitespace-no-wrap mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                            >
                                                                                {!agent.open && <span className="mr-1">Show more</span>}{agent.open && <span className="mr-1">Hide</span>}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={agent.email} className={(agent.open ? " h-140 sm:h-140 border-b" : "h-0 overflow-hidden") + " transition-height duration-500 ease-in-out border-gray-100 relative"}>
                                                                        {
                                                                            agent.open &&
                                                                            <div className="absolute w-full bg-gray-50 h-140 sm:h-140">
                                                                                <div className="w-full h-full p-6">
                                                                                    <div className=" bg-white rounded-lg w-full h-full grid grid-cols-12">
                                                                                        <div className="col-span-4 hidden sm:block">
                                                                                            <div className="w-full h-full flex flex-col">
                                                                                                <div className="w-full">
                                                                                                    <div className="px-4 py-5 sm:px-6">
                                                                                                        <h2 className="text-sm font-medium leading-6 text-gray-900">Client information</h2>
                                                                                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Client name</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-span-12 sm:col-span-4 flex flex-col sm:border-r sm:border-l">
                                                                                            <div className="flex flex-1 flex-col">
                                                                                                <div className="px-4 py-5 sm:px-6">
                                                                                                    <h2 className="text-sm font-medium leading-6 text-gray-900">WhatsApp messages</h2>
                                                                                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">From: {agent._id}</p>
                                                                                                </div>
                                                                                                <div className="flex flex-1">
                                                                                                    <div className="flex flex-1 flex-col bg-whatsapp-200 p-6 overflow-y-auto">
                                                                                                        <div className="flex flex-1"></div>
                                                                                                        <div className="max-h-115 overflow-y-auto">
                                                                                                            {
                                                                                                                Array.isArray(agent.messages) &&
                                                                                                                agent.messages.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <div className={(index !== 0 ? "mt-2" : "") + " w-full flex flex-row"}>
                                                                                                                            {
                                                                                                                                item.enterprise &&
                                                                                                                                <div className="flex flex-1"></div>
                                                                                                                            }
                                                                                                                            <div className={(item.enterprise ? "bg-whatsapp-100" : "bg-whatsapp-300") + " rounded-lg shadow-sm p-2 px-4 text-xs"}>
                                                                                                                                {
                                                                                                                                    (item.template === 'template_response' || item.template === 'regular_message') &&
                                                                                                                                    <span>{item.template} | </span>
                                                                                                                                }
                                                                                                                                {item.message}
                                                                                                                                <div className="text-xxxs mt-1 text-right text-gray-600 capitalize">{moment(item.createdAt).format("MM/DD hh:mm A")}</div>
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                !item.enterprise &&
                                                                                                                                <div className="flex flex-1"></div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                false &&
                                                                                                <div className="w-full bg-white p-6 flex-col">
                                                                                                    <textarea
                                                                                                        value={agent.message}
                                                                                                        onChange={(event) => {
                                                                                                            agent.message = event.target.value;
                                                                                                            this.setState({
                                                                                                                users: this.state.users
                                                                                                            });
                                                                                                        }}
                                                                                                        placeholder={"Type a message"}
                                                                                                        className="appearance-none border-none h-12 block w-full border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                                                                                    <div className="w-full mt-4">
                                                                                                        <div
                                                                                                            onClick={async () => {
                                                                                                                if (agent.message && agent.message !== "" && !agent.loading_message) {
                                                                                                                    //this.functions.sendMessage(agent, agent.message);
                                                                                                                }
                                                                                                            }}
                                                                                                            className={((agent.message && agent.message !== "" && !agent.loading_message) ? "cursor-pointer" : "cursor-not-allowed opacity-50") + " w-full border justify-center text-center flex mr-2 relative items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                                        >
                                                                                                            Send message
                                                                                                            {
                                                                                                                agent.loading_message &&
                                                                                                                <div className="w-full absolute h-full flex justify-center items-center">
                                                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                                                        class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-span-4 flex-col hidden sm:flex">
                                                                                            <div className="flex flex-col flex-1">
                                                                                                <div className="px-4 py-5 sm:px-6">
                                                                                                    <h2 className="text-sm font-medium leading-6 text-gray-900">Cards</h2>
                                                                                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Select cards from listings, agents and files</p>
                                                                                                </div>
                                                                                                <div className="w-full">
                                                                                                    <div className="border-b p-4 px-6  border-gray-100">
                                                                                                        {
                                                                                                            [
                                                                                                                { name: 'Listings' },
                                                                                                                { name: 'Agents' },
                                                                                                                { name: 'Files' },
                                                                                                            ].map((item) => (
                                                                                                                <div
                                                                                                                    onClick={async () => {

                                                                                                                    }}
                                                                                                                    className={"cursor-pointer mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                                                                >
                                                                                                                    {item.name}
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            agent.loading && agent.open &&
                                                                                            <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex justify-center items-center bg-white bg-opacity-50">
                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                    class="w-8 h-8 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.users.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className="hidden sm:block">
                                                    <p className="text-sm text-gray-700">
                                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.total ? this.state.total : (this.state.page * this.state.page_count)}</span> of{' '}
                                                        <span className="font-medium">{this.state.total}</span> results
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        this.functions.getNumbers();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            this.setState({
                                                                select_all: false,
                                                                page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                            }, () => {
                                                                this.functions.getNumbers();
                                                            });
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    console.log(this.state.page);
                                                                    this.functions.getNumbers();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div>
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div>
        )
    }
}

export default ListWhatsappMessages;
